import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useOutletContext } from "react-router-dom";
// helpers
import { pagesUrl, appSection, providerRootUrl } from "../../common/utils/panel-helpers/helpers";
import { getEditOfferDataFormattedBySteps } from "./offersHelpers";
// components
import SectionLoader from "../../components/SectionLoader";
import FormMessage from "../../components/FormMessage";
import { CustomButton } from "@gl-shared/shared/ui";
// Hotel Offers components
import OffersList from "./OffersList";
// actions
import { offersActionCreators } from "../../common/store/modules/offersModule";
import { globalActionCreators } from "../../common/store/modules/globalModule";
import { errorFieldActionCreators } from "../../common/store/modules/errorFieldModule";

const Offers = props => {
  const outletContext = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    // call loadOffers only if there are no offers on redux already & if there aren't previous loadOffers call
    if (props.panelOffers === null) {
      loadOffers(undefined);
    }
  }, [props.panelOffers]);

  // resetting Offer and Error Field states when user clicks on "Add Offer" || "Edit" button
  const resetOfferState = () => {
    // Reset all steps data to initial values & reset to initial step
    props.resetOfferStepDataAction();
    // Reset errorFieldModule state
    props.resetErrorFieldAction();
  };

  const handleEditOfferButton = offer => {
    resetOfferState();
    // reset isFormChanged to default false value
    props.setIfFormChangedAction(false);
    navigate(`/${providerRootUrl}/offers/edit/${offer?.event_code}`);
    const formattedOffer = getEditOfferDataFormattedBySteps(offer);
    props.setOfferForEditAction(formattedOffer);
    window.scrollTo(0, 0);
  };

  const getMaximumNumOfOffers = () => "unlimited";

  const handleAddOfferButton = () => {
    resetOfferState();
    // reset isFormChanged to default false value
    props.setIfFormChangedAction(false);

    navigate(`/${providerRootUrl}/${pagesUrl.newOffer}`);
  };

  const loadOffers = hotelId => props.loadOffersAction(hotelId, outletContext.handleSectionLoader);

  return (
    <div className="hotel-offers component">
      {props.sectionLoadingNames?.includes(appSection?.offers) ? (
        <SectionLoader loadingLabel="LOADING OFFERS" />
      ) : (
        <>
          <>
            <FormMessage
              form={props.formData?.form}
              formError={props.formData?.formError}
              formSuccessful={props.formData?.formSuccessful}
              hotelName={props.formData?.hotelName}
            />
            <div className="row hotel-offers__box hotel-offers__box--max-width-900">
              <div className="col-lg-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Offers</h5>
                    <div className="ibox-button_section ibox-button_section--margin-right">
                      <CustomButton
                        isDisabled={
                          Number.isInteger(getMaximumNumOfOffers())
                            ? props?.panelOffers?.length > getMaximumNumOfOffers() - 1
                            : false
                        }
                        mainClass="btn-green"
                        customClasses="btn-green--height-l btn-green--auto-width"
                        // customClasses={
                        //   props?.panelOffers.length > maximumNumberOfOffers - 1 && "btn-green--disabled"
                        // }
                        onClick={handleAddOfferButton}
                        buttonText="Add Offer"
                      />
                    </div>
                  </div>

                  <OffersList
                    hotelCurrency={props.hotelData?.hotelCurrency}
                    panelOffers={props?.panelOffers}
                    onEditButton={handleEditOfferButton}
                  />
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  panelOffers: state.offers.panelOffers,
  sectionLoadingNames: state.global.sectionLoadingNames,
  formData: state.global.formData,
  hotelData: state.home.hotelData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setOfferForEditAction: offersActionCreators.setOfferForEditAction,
      setIfFormChangedAction: offersActionCreators.setIfFormChangedAction,
      loadOffersAction: offersActionCreators.loadOffersAction,
      toggleSectionLoaderAction: globalActionCreators.toggleSectionLoaderAction,
      setFormDataAction: globalActionCreators.setFormDataAction,
      resetOfferStepDataAction: offersActionCreators.resetOfferStepDataAction,
      resetErrorFieldAction: errorFieldActionCreators.resetErrorFieldAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
