import { useNavigate } from "react-router-dom";
// components
import LoginOverlay from "../../../components/LoginOverlay";
// helpers
import { providerRootUrl, pagesUrl } from "../../../common/utils/panel-helpers/helpers";

export default function InvalidToken() {
  const navigate = useNavigate();

  const onBackToLoginClick = () => {
    navigate(`/${providerRootUrl}/${pagesUrl.login}`);
  };

  return (
    <div className="reset-password">
      <LoginOverlay
        backgroundImageClass="provider-login-page"
        form={
          <div className="screen-wrapper">
            <div className="text-instructions text-instructions--warning">
              Sorry, the link has expired or is not correct.
              <br />
              Please try again.
            </div>
            <div className="separator-div" />
            <div onClick={onBackToLoginClick} className="reset-password-submit-button">
              BACK TO LOGIN
            </div>
          </div>
        }
        infoSection={
          <div className="reset-password__info-section">
            <div className="big-title">RESET</div>
            <div className="small-title">PASSWORD</div>
          </div>
        }
      />
    </div>
  );
}
