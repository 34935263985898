import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import moment from "moment";
// components
import NoData from "../../components/NoData";
// helpers
import {
  exportProviderBookingsCsv,
  getExportFilename,
  getSumOfUnitsForOffer
} from "../../common/utils/panel-helpers/helpers";
// icons
// @ts-ignore
import { arrowDownWhiteIcon } from "@gl-shared/shared/vector";
// actions
import { useActions } from "../../common/hooks/useActions";
import { bookingsActionCreators } from "../../common/store/modules/bookingsModule";

const Bookings = (props: any) => {
  const [visibleBookings, setVisibleBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages]: any = useState([]);

  useEffect(() => {
    loadPages();
  }, [props.filteredBookings]);

  // actions
  const setPaginationBookingsPage = useActions(bookingsActionCreators?.setPaginationBookingsPageAction, []);

  const loadPages = () => {
    let totalPages;
    totalPages = Math.ceil(props.totalNumber / props.numberItemPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    setAllPages(pages);
    setFilteredBookings(props.filteredBookings);
    setVisibleBookings(props.filteredBookings);
  };

  const switchPage = (page: any) => {
    window.scrollTo(0, 0);
    let newBookings = filteredBookings[page - 1];
    setVisibleBookings(newBookings);
    setCurrentPage(page);
    setPaginationBookingsPage(page);
  };

  const handleExportCsv = () => {
    const filename = getExportFilename("GETLOCAL_Bookings_", props?.dateRangeSelection, true);
    exportProviderBookingsCsv(visibleBookings, props?.hotelCurrency, props?.hotelName, filename);
  };

  return (
    <div className="bookings component">
      {props.requestError === true && <NoData message="RequestError" />}
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <div>
                <h5>Bookings</h5>
              </div>
              <div className="export-container">
                <div
                  className={`export-container__button ${
                    props.filteredBookings?.length === 0 || props.filteredBookings === null ? "export-disabled" : ""
                  }`}
                  onClick={handleExportCsv}
                >
                  CSV <img src={arrowDownWhiteIcon} alt="CSV" />
                </div>
                {/*                    <div
                      className={`export-container__button export-pdf ${
                        this.props.filteredBookings?.length === 0 ? "export-disabled" : ""
                      }`}
                      onClick={this.handleExportCsv}
                    >
                      PDF <img src={arrowDownWhiteIcon} alt="PDF" />
                    </div>*/}
              </div>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table__bookings table table-striped">
                  <thead>
                    <tr>
                      <th>Booking Code </th>
                      <th>Booking Date </th>
                      <th>Booking For Date</th>
                      <th>Offer</th>
                      <th>Total units</th>
                      <th>Distributor</th>
                      <th>Customer</th>
                      <th>Total Price</th>
                      <th>Commission</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibleBookings?.length > 0 ? (
                      visibleBookings.map((booking: any) => {
                        return (
                          <tr key={booking.booking_code}>
                            <td>{booking.booking_code} </td>
                            <td>{format(new Date(booking?.created_at), "dd.MM.yyyy - HH:mm")}</td>
                            <td>{format(new Date(booking?.event_date), "dd.MM.yyyy") + " - " + booking.event_time}</td>
                            <td>{booking.event.name}</td>
                            <td>{getSumOfUnitsForOffer(booking)}</td>
                            <td>{booking?.distributor?.name}</td>
                            <td>
                              {booking.external_customer_name === props.hotelName ||
                              booking.external_customer_name === null
                                ? booking.customer.first_name + " " + booking.customer.last_name
                                : booking.external_customer_name}
                            </td>
                            <td>
                              {props.hotelCurrency} {booking.total_price_without_fees}
                            </td>
                            <td>
                              {booking?.commission_booking?.total_provider_commission
                                ? `${
                                    props.hotelCurrency
                                  } ${booking?.commission_booking?.total_provider_commission.toFixed(2)}`
                                : "-"}
                            </td>
                            <td>{booking.status}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="table-error-message">No bookings in the selected timeframe</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <nav>
                  {allPages && allPages.length > 1 && (
                    <ul className="pagination justify-content-center">
                      <li key="0" className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                        <a className="page-link" onClick={() => switchPage(currentPage - 1)}>
                          Previous
                        </a>
                      </li>
                      {allPages.map((page: any) => (
                        <li key={page} className={currentPage === page ? "page-item active" : "page-item"}>
                          <a className="page-link" onClick={() => switchPage(page)}>
                            {page}
                          </a>
                        </li>
                      ))}
                      <li key="-1" className={allPages.includes(currentPage + 1) ? "page-item" : "page-item disabled"}>
                        <a className="page-link" onClick={() => switchPage(currentPage + 1)}>
                          Next
                        </a>
                      </li>
                    </ul>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  requestError: state.global.requestError,
  filteredBookings: state.bookings.filteredBookings,
  currentPage: state.bookings.currentPage,
  numberItemPerPage: state.bookings.numberItemPerPage,
  totalNumber: state.bookings.totalNumber,
  hotelName: state.home.hotelData?.hotelName,
  hotelCurrency: state.home.hotelData?.hotelCurrency,
  dateRangeSelection: state.home.dateRangeSelection
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      loadBookingsAction: bookingsActionCreators.loadBookingsAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
