import { useSelector } from "react-redux";
// Components
import NoData from "../../components/NoData";
import GraphBookingsDevProvider from "./GraphBookingsDevProvider";
// actions
import { useActions } from "../../common/hooks/useActions";
import { dashboardActionCreators } from "../../common/store/modules/dashboardModule";

const Dashboard = () => {
  let key = 0;

  // states
  const tableDataInDashboard = useSelector(state => state.dashboard.tableDataInDashboard);
  const requestError = useSelector(state => state.global.requestError);
  const noBookingsData = useSelector(state => state.bookings.noBookingsData);
  const dateRangeSelection = useSelector(state => state.home.dateRangeSelection);
  const mainGraphData = useSelector(state => state.dashboard.mainGraphData);
  const dashboardData = useSelector(state => state.dashboard.dashboardData);
  const hotelCurrency = useSelector(state => state.home.hotelData?.hotelCurrency);

  // actions
  const setTableDataInDashboard = useActions(dashboardActionCreators?.setTableDataInDashboardAction, []);

  const sortTableDashboardData = (column, statisticData, statisticDataName) => {
    const direction = statisticData?.column ? (statisticData.direction === "asc" ? "desc" : "asc") : "asc";

    let sortedData = [];
    if (column === "id") {
      const ids = statisticData?.sortedData.map((obj, index) => index + 1);
      ids.sort((a, b) => {
        const idA = statisticData?.sortedData[a - 1].name.toUpperCase();
        const idB = statisticData?.sortedData[b - 1].name.toUpperCase();

        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }
        return 0;
      });

      sortedData = ids.map(id => statisticData?.sortedData[id - 1]);
    } else {
      sortedData = statisticData?.sortedData.sort((a, b) => {
        if (column === "name") {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        } else {
          return a[column] - b[column];
        }
      });
    }
    if (direction === "desc") {
      sortedData.reverse();
    }

    setTableDataInDashboard({
      ...tableDataInDashboard,
      [statisticDataName]: { sortedData, column, direction }
    });
  };

  return (
    <div className="dashboard component">
      {dashboardData?.totalBookings === 0 && noBookingsData === true ? (
        <NoData message="NoData" />
      ) : requestError === true ? (
        <NoData message="RequestError" />
      ) : null}
      <div>
        <div className="row">
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Bookings</h5>
              </div>
              <div className="ibox-content">
                <span className="no-margins" data-cy="booking-counter">
                  {dashboardData?.totalBookings}
                </span>
                <small>New bookings</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Revenue</h5>
              </div>
              <div className="ibox-content">
                <span className="no-margins">
                  {hotelCurrency} {dashboardData?.averagesRevenuePerBookings?.toFixed(2)}
                </span>
                <small>Revenue</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Averages</h5>
              </div>
              <div className="ibox-content averages">
                <div className="averages__section">
                  <span className="no-margins">{dashboardData?.averagesUnitsPerBookings?.toFixed(2)}</span>
                  <small>Units</small>
                </div>
                <div className="averages__section">
                  <span className="no-margins">
                    {hotelCurrency} {dashboardData?.totalRevenue?.toFixed(2)}
                  </span>
                  <small>Average revenue</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Development of bookings</h5>
              </div>
              <div className="ibox-content">
                {mainGraphData?.length > 0 && (
                  <GraphBookingsDevProvider mainGraphData={mainGraphData} dateRangeSelection={dateRangeSelection} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <>
            <div className="col-lg-6">
              <div className="ibox ">
                <div className="ibox-title">
                  <h5>Sales by Offer</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive overflow-hid">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData("id", tableDataInDashboard["salesByOffer"], "salesByOffer")
                            }
                          >
                            <span>#</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData("name", tableDataInDashboard["salesByOffer"], "salesByOffer")
                            }
                          >
                            <span>Offer</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData("bookings", tableDataInDashboard["salesByOffer"], "salesByOffer")
                            }
                          >
                            <span>Bookings</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData(
                                "booking_revenue",
                                tableDataInDashboard["salesByOffer"],
                                "salesByOffer"
                              )
                            }
                          >
                            <span>Revenue</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableDataInDashboard["salesByOffer"]?.sortedData &&
                          tableDataInDashboard["salesByOffer"]?.sortedData.map((employee, index) => {
                            return (
                              <tr key={key++}>
                                <th>{++index}</th>
                                <th>{employee.name}</th>
                                <th>{employee.bookings}</th>
                                <th>
                                  {hotelCurrency}{" "}
                                  {parseFloat(employee.booking_revenue).toLocaleString("de-ch", {
                                    minimumFractionDigits: 2
                                  })}
                                </th>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ibox ">
                <div className="ibox-title">
                  <h5>Sales by Distributor</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive overflow-hid">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData(
                                "id",
                                tableDataInDashboard["salesByDistributor"],
                                "salesByDistributor"
                              )
                            }
                          >
                            <span>#</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData(
                                "name",
                                tableDataInDashboard["salesByDistributor"],
                                "salesByDistributor"
                              )
                            }
                          >
                            <span>Distributor</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData(
                                "bookings",
                                tableDataInDashboard["salesByDistributor"],
                                "salesByDistributor"
                              )
                            }
                          >
                            <span>Bookings</span>
                          </th>
                          <th
                            className="employee_table-head"
                            onClick={() =>
                              sortTableDashboardData(
                                "booking_revenue",
                                tableDataInDashboard["salesByDistributor"],
                                "salesByDistributor"
                              )
                            }
                          >
                            <span>Revenue</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableDataInDashboard["salesByDistributor"]?.sortedData &&
                          tableDataInDashboard["salesByDistributor"]?.sortedData.map((employee, index) => {
                            return (
                              <tr key={key++}>
                                <th>
                                  <span>{++index}</span>
                                </th>
                                <th>
                                  <span>{employee.name}</span>
                                </th>
                                <th>
                                  <span>{employee.bookings}</span>
                                </th>
                                <th>
                                  <span>
                                    {hotelCurrency}{" "}
                                    {parseFloat(employee.booking_revenue).toLocaleString("de-ch", {
                                      minimumFractionDigits: 2
                                    })}
                                  </span>
                                </th>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
