import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useOutletContext } from "react-router-dom";
// components
import FormMessage from "../../components/FormMessage";
import SectionLoader from "../../components/SectionLoader";
import { CustomInput } from "@gl-shared/shared/ui";
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomButton } from "@gl-shared/shared/ui";
// helpers
import { appSection } from "../../common/utils/panel-helpers/helpers";
import { checkForEmptyField, checkForValidEmail } from "@gl-shared/shared/helpers";
// actions
import { settingsActionCreators } from "../../common/store/modules/settingsModule";

const ProviderSettings = props => {
  const [providerName, setProviderName] = useState("");
  const [providerStreet, setProviderStreet] = useState("");
  const [providerStreetNumber, setProviderStreetNumber] = useState("");
  const [providerZIP, setProviderZIP] = useState("");
  const [providerCity, setProviderCity] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [providerPhone, setProviderPhone] = useState("");
  const [errorField, setErrorField] = useState({
    name: false,
    street: false,
    streetNumber: false,
    zip: false,
    city: false,
    email: false,
    phone: false
  });

  const outletContext = useOutletContext();

  useEffect(() => {
    fillInInputsFromSettingsData();
  }, [props.hotelSettingsData?.name]);

  const fillInInputsFromSettingsData = () => {
    setProviderName(props.hotelSettingsData?.name || "");
    setProviderStreet(props.hotelSettingsData?.address?.street || "");
    setProviderStreetNumber(props.hotelSettingsData?.address?.house_number || "");
    setProviderZIP(props.hotelSettingsData?.address?.zip_code || "");
    setProviderCity(props.hotelSettingsData?.address?.city || "");
    setProviderEmail(props.hotelSettingsData?.email || "");
    setProviderPhone(props.hotelSettingsData?.address?.phone_number || "");
  };

  const handleSubmit = async () => {
    let errorCheck = true;
    let errorField = {
      name: false,
      street: false,
      streetNumber: false,
      zip: false,
      city: false,
      email: false,
      phone: false
    };

    if (providerName === "") {
      errorCheck = false;
      errorField.name = true;
    }
    if (providerStreet === "") {
      errorCheck = false;
      errorField.street = true;
    }
    if (providerStreetNumber === "") {
      errorCheck = false;
      errorField.streetNumber = true;
    }
    if (providerZIP === "") {
      errorCheck = false;
      errorField.zip = true;
    }
    if (providerCity === "") {
      errorCheck = false;
      errorField.city = true;
    }
    if (checkForEmptyField(providerEmail) || checkForValidEmail(providerEmail)) {
      errorCheck = false;
      errorField.email = true;
    }
    if (providerPhone === "") {
      errorCheck = false;
      errorField.phone = true;
    }
    setErrorField(errorField);

    if (errorCheck === false) {
      return false;
    }

    props.updateSettingsAction(
      providerName,
      null,
      providerEmail,
      providerStreet,
      providerStreetNumber,
      providerZIP,
      providerCity,
      providerPhone,
      null,
      null,
      null,
      outletContext.handleFormMessage,
      outletContext.loadSettings
    );
  };

  return (
    <div className="employees component">
      {props.sectionLoadingNames?.includes(appSection?.providerSettings) ? (
        <SectionLoader loadingLabel="LOADING SETTINGS" />
      ) : (
        <>
          {props.formData?.form === "Settings" ? (
            <FormMessage
              form={props.formData?.form}
              formError={props.formData?.formError}
              formSuccessful={props.formData?.formSuccessful}
            />
          ) : null}
          {props.formData?.form === "HotelSelectionNotice" && (
            <FormMessage form={props.formData?.form} hotelName={props.formData?.hotelName} />
          )}
          <div className="row">
            <div className="col-lg-8">
              <div className="ibox form__ibox">
                <div className="ibox-title">
                  <h5>Provider Settings</h5>
                </div>
                <div className="ibox-content">
                  <div>
                    <CustomFormInput
                      showTooltip={false}
                      name="providerName"
                      id="name"
                      label="Provider Name"
                      onInputChange={event => setProviderName(event.target.value)}
                      errorField={errorField}
                      isRequired={true}
                      value={providerName}
                    />

                    <div className="form-group  row">
                      <label className="col-sm-4 col-form-label settings__label field--required">Street & Number</label>
                      <div className="col-sm-6">
                        <CustomInput
                          showLabel={false}
                          name="providerStreet"
                          id="street"
                          onInputChange={event => setProviderStreet(event.target.value)}
                          errorField={errorField}
                          isRequired={true}
                          value={providerStreet}
                        />
                      </div>
                      <div className="col-sm-2">
                        <CustomInput
                          showLabel={false}
                          name="providerStreetNumber"
                          id="streetNumber"
                          onInputChange={event => setProviderStreetNumber(event.target.value)}
                          errorField={errorField}
                          isRequired={true}
                          value={providerStreetNumber}
                        />
                      </div>
                    </div>
                    <div className="form-group  row">
                      <label className="col-sm-4 col-form-label settings__label field--required">ZIP & City</label>
                      <div className="col-sm-3">
                        <CustomInput
                          showLabel={false}
                          name="providerZIP"
                          id="zip"
                          onInputChange={event => setProviderZIP(event.target.value)}
                          errorField={errorField}
                          isRequired={true}
                          value={providerZIP}
                        />
                      </div>
                      <div className="col-sm-5">
                        <CustomInput
                          showLabel={false}
                          name="providerCity"
                          id="city"
                          onInputChange={event => setProviderCity(event.target.value)}
                          errorField={errorField}
                          isRequired={true}
                          value={providerCity}
                        />
                      </div>
                    </div>

                    <div className="form-group  row">
                      <label className="col-sm-4 col-form-label field--required">E-Mail</label>
                      <div className="col-sm-8 pw-field__container">
                        <CustomInput
                          name="providerEmail"
                          id="email"
                          type="providerEmail"
                          errorField={errorField}
                          onInputChange={event => setProviderEmail(event.target.value)}
                          value={providerEmail}
                          isRequired={true}
                          showLabel={false}
                        />
                        <div className="pw-field__message">e-mail address used for billing and updates</div>
                      </div>
                    </div>
                    <CustomFormInput
                      maskedNumberInput={true}
                      maskedPhoneNumber={true}
                      showTooltip={false}
                      name="providerPhone"
                      id="phone"
                      label="Phone"
                      onInputBlur={value => setProviderPhone(value)}
                      errorField={errorField}
                      isRequired={true}
                      value={providerPhone}
                    />

                    <div className="form-group  row settings__submit">
                      <CustomButton
                        isDisabled={false}
                        mainClass="btn-green"
                        customClasses="btn-green--height-l"
                        onClick={handleSubmit}
                        buttonText="SAVE"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  hotelSettingsData: state.settings.hotelSettingsData,
  formData: state.global.formData,
  sectionLoadingNames: state.global.sectionLoadingNames
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateSettingsAction: settingsActionCreators.updateSettingsAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSettings);
