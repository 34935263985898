import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// helpers
import { providerRootUrl, pagesUrl } from "../../utils/panel-helpers/helpers";

const PrivateOffersRoute = ({ children }: any) => {
  // states
  const panelOffers = useSelector((state: any) => state.offers.panelOffers);

  const isAddEditOfferPageAvailable = panelOffers?.length !== null && panelOffers?.length > 0;

  if (isAddEditOfferPageAvailable) {
    return children;
  } else {
    return <Navigate to={`/${providerRootUrl}/${pagesUrl?.offers}`} />;
  }
};

export default PrivateOffersRoute;
