// icons
// @ts-ignore
import { errorIcon } from "@gl-shared/shared/vector";

interface NoDataProps {
  message: "NoData" | "NoOffers" | "NoOffersFound" | "RequestError";
}

const NoData = ({ message }: NoDataProps) => {
  return (
    <div>
      <div className="nodata">
        <img className="error__icon" src={errorIcon} alt="error-icon" />
        {message === "NoData" ? (
          <div className="nodata__text">
            There was no data found for the selected dates. Please select a different date range.
          </div>
        ) : null}
        {message === "NoOffers" ? (
          <div className="nodata__text">No offers are currently available in this category.</div>
        ) : null}
        {message === "NoOffersFound" ? (
          <div className="nodata__text">No offers were found in this category or with your search term.</div>
        ) : null}
        {message === "RequestError" ? (
          <div className="nodata__text">
            Sorry, something went wrong. Please try again or contact support@get-local.com. We apologize for the
            inconvenience.
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NoData;
