import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

// App Layout for non-authenticated routes

export default function AppLayout() {
  return (
    <div className="app-layout" data-cy="provider">
      <Box className="app-container">
        <div id="page-wrapper">
          <div className="wrapper wrapper-content">
            <Outlet />
          </div>
        </div>
      </Box>
    </div>
  );
}
