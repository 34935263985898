import Select, { components } from "react-select";
import { useEffect, useRef, useState } from "react";
// components
import { SwitchToggle } from "@gl-shared/shared/ui";
// icons
import { addIcon, downArrowIcon, removeIcon } from "@gl-shared/shared/vector";

const AvailabilityTimeslotsPerType = props => {
  const [borderWidth, setBorderWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    // GET COMPUTED WIDTHS AND UPDATE STATE FOR HANDLING MENU LIST WIDTH
    if (elementRef.current === null) return;
    setBorderWidth(getComputedStyle(elementRef.current?.children[0])?.borderInlineWidth);
  }, []);
  // check if Arrow Keyboard issue still persists in the future (react-select library Option Component)
  const Options = props => {
    const ref = useRef();

    useEffect(() => {
      props.isSelected && ref.current.scrollIntoView();
    }, [props.isSelected]);

    return <components.Option {...props} innerRef={ref} />;
  };

  //render inputs
  const renderInputs = (index, day) => {
    switch (props.chosenInputType) {
      case "fixed":
        return (
          <>
            {props.inputs?.length > 0 &&
              props.inputs?.map((input, index) => {
                return (
                  <div key={index} className="timeslot-wrapper">
                    <div className="timeslot" ref={elementRef}>
                      <Select
                        components={{ Option: Options }}
                        isOptionSelected={option => (option.value == input?.value ? true : false)}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          control: (baseStyles, state) => ({
                            ...baseStyles
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "75px",
                            left: "-" + borderWidth
                          })
                        }}
                        value={input.value}
                        options={props.getOptionsForDropdowns}
                        placeholder={input.value}
                        className={
                          props.availabilityErrorFixed?.[day]?.[index]?.error
                            ? "timeslot__select invalid__input"
                            : "timeslot__select"
                        }
                        classNamePrefix="timeslot"
                        onChange={selectedItem => {
                          props.onChangeDropdownTimeslot(selectedItem, day, index);
                        }}
                      />
                      <img className="timeslot__dropdown-arrow" src={downArrowIcon} alt="dropdown-svg-icon" />
                    </div>
                    <div
                      onClick={() => {
                        props.removeTimeslot(index, day);
                      }}
                      className="timeslot__remove"
                    >
                      <img src={removeIcon} alt="remove-icon" />
                    </div>
                    {index === props.inputs?.length - 1 && (
                      <img
                        onClick={() => {
                          props.addTimeslot(index, day);
                        }}
                        className={
                          props.addTimeslotDisableStatusPerDay[day]?.isDisabled
                            ? "timeslot__add-button timeslot__add-button--disabled concierge__select--plus"
                            : "timeslot__add-button concierge__select--plus"
                        }
                        src={addIcon}
                        alt="add-button-icon"
                      />
                    )}
                  </div>
                );
              })}
            {props.availabilityErrorMsg?.[day]?.error && (
              <div className="timeslot__error-msg timeslot__error-msg--fixed-type">
                {props.availabilityErrorMsg?.[day]?.availabilityErrorMsg}
              </div>
            )}
            {props.inputs.length === 0 ? (
              <div className="timeslot-wrapper">
                <div className="empty-timeslots-wrapper">
                  <img
                    onClick={() => {
                      props.addTimeslot(0, day);
                    }}
                    className={
                      props.addTimeslotDisableStatusPerDay[day]?.isDisabled
                        ? "timeslot__add-button timeslot__add-button--disabled concierge__select--plus"
                        : "timeslot__add-button concierge__select--plus"
                    }
                    src={addIcon}
                    alt="add-button-icon"
                  />
                </div>
              </div>
            ) : null}
          </>
        );
      case "timeslotIntervals":
        return (
          <>
            {props.inputs["from"]?.length > 0 &&
              props.inputs["from"].map((input, index) => {
                return (
                  <div key={index} className="timeslot__intervals">
                    <div className="timeslot" ref={elementRef}>
                      <Select
                        components={{ Option: Options }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          control: (baseStyles, state) => ({
                            ...baseStyles
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "75px",
                            left: "-" + borderWidth
                          })
                        }}
                        value={{
                          value: props.inputs["from"][index].value,
                          label: props.inputs["from"][index].label
                        }}
                        onChange={selectedItem => props.onChangeDropdownTimeslot(selectedItem, day, index, "from")}
                        options={props.getOptionsForDropdowns}
                        placeholder={props.inputs["from"][index].value}
                        className={
                          props.availabilityErrorIntervals?.[day]?.["from"]?.[index]?.error ||
                          props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.errorValidationMsg
                            ? "timeslot__select invalid__input"
                            : "timeslot__select"
                        }
                        classNamePrefix="timeslot"
                      />
                      <img className="timeslot__dropdown-arrow" src={downArrowIcon} alt="dropdown-svg-icon" />
                    </div>
                    <span className="timeslot-between">to</span>
                    <div className="timeslot" ref={elementRef}>
                      <Select
                        components={{ Option: Options }}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          control: (baseStyles, state) => ({
                            ...baseStyles
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "75px",
                            left: "-" + borderWidth
                          })
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        value={{
                          value: props.inputs["to"][index].value,
                          label: props.inputs["to"][index].label
                        }}
                        onChange={selectedItem => props.onChangeDropdownTimeslot(selectedItem, day, index, "to")}
                        options={props.getOptionsForDropdowns}
                        placeholder={props.inputs["to"][index].value}
                        className={
                          props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.error ||
                          props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.errorValidationMsg
                            ? "timeslot__select invalid__input"
                            : "timeslot__select"
                        }
                        classNamePrefix="timeslot"
                      />
                      <img className="timeslot__dropdown-arrow" src={downArrowIcon} alt="dropdown-svg-icon" />
                    </div>
                    <div
                      onClick={() => {
                        props.removeTimeslot(index, day);
                      }}
                      className="timeslot__remove"
                    >
                      <img src={removeIcon} alt="remove-icon" />
                    </div>
                    {index === props.inputs["from"]?.length - 1 && (
                      <img
                        onClick={() => {
                          props.addTimeslot(index, day);
                        }}
                        className={
                          props.addTimeslotDisableStatusPerDay[day]?.isDisabled
                            ? props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.errorValidationMsg
                              ? "timeslot__add-button timeslot__add-button--up timeslot__add-button--disabled concierge__select--plus"
                              : "timeslot__add-button timeslot__add-button--disabled concierge__select--plus"
                            : "timeslot__add-button concierge__select--plus"
                        }
                        src={addIcon}
                        alt="add-button-icon"
                      />
                    )}
                    {props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.errorValidationMsg && (
                      <div className="timeslot__error-msg">
                        {props.availabilityErrorIntervals?.[day]?.["to"]?.[index]?.errorValidationMsg}
                      </div>
                    )}
                  </div>
                );
              })}
            {props.availabilityErrorMsg?.[day]?.availabilityErrorMsg && (
              <div className="timeslot__error-msg">{props.availabilityErrorMsg?.[day]?.availabilityErrorMsg}</div>
            )}
            {props.inputs["from"].length === 0 ? (
              <div className="empty-timeslots-wrapper">
                <img
                  onClick={() => {
                    props.addTimeslot(0, day);
                  }}
                  className={
                    props.addTimeslotDisableStatusPerDay[day]?.isDisabled
                      ? "timeslot__add-button timeslot__add-button--disabled concierge__select--plus"
                      : "timeslot__add-button concierge__select--plus"
                  }
                  src={addIcon}
                  alt="add-button-icon"
                />
              </div>
            ) : null}
          </>
        );

      case "onePerAllDay":
        return (
          <>
            {props.inputs["inputValues"]?.length > 0 &&
              props.inputs["inputValues"].map((input, index) => {
                return (
                  <div key={index} className="timeslot-per-day">
                    <SwitchToggle
                      value={props.inputs.toggle}
                      checked={props.inputs.toggle}
                      onChange={() => props.addTimeslot(index, day)}
                    />
                    {props.inputs.toggle && (
                      <>
                        <span className="timeslot-between">bookable until</span>
                        <div key={index} className="timeslot" ref={elementRef}>
                          <Select
                            components={{ Option: Options }}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                              control: (baseStyles, state) => ({
                                ...baseStyles
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "75px",
                                left: "-" + borderWidth
                              })
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            value={{
                              value: props.inputs["inputValues"][index].value,
                              label: props.inputs["inputValues"][index].label
                            }}
                            onChange={selectedItem => props.onChangeDropdownTimeslot(selectedItem, day, index)}
                            options={props.getOptionsForDropdowns}
                            placeholder={""}
                            className="timeslot__select"
                            classNamePrefix="timeslot"
                          />

                          <img className="timeslot__dropdown-arrow" src={downArrowIcon} alt="dropdown-svg-icon" />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </>
        );
      default:
        return;
    }
  };

  return renderInputs(props.index, props.day);
};

export default AvailabilityTimeslotsPerType;
