// helpers
import {
  pagesUrl,
  providerRootUrl,
  getFutureDateFromCurrentDate,
  getCookie,
  isTokenExpired
} from "../../common/utils/panel-helpers/helpers";
import { isUserLandsOnLogin } from "./helpers/isUserLandsOnLogin";

export const loginActionTypes = {
  SET_USER: "SET_USER",
  TOGGLE_ERROR_MESSAGE: "TOGGLE_ERROR_MESSAGE"
};

interface loginModuleTypes {
  userToken: string | null;
  isUserAuthenticated: null | true | false;
  errorMessage: string | null;
}

export const initialState: loginModuleTypes = {
  userToken: null,
  isUserAuthenticated: null, // null is initial value, false is when user is not authenticated, true when it is authenticated
  errorMessage: null
};

const loginModule = (state: loginModuleTypes = initialState, action: any) => {
  switch (action.type) {
    case loginActionTypes.SET_USER:
      return {
        ...state,
        userToken: action.userToken,
        isUserAuthenticated: action.isUserAuthenticated
      };

    case loginActionTypes.TOGGLE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
};

export default loginModule;

export const loginActionCreators = {
  getUserLoginAction:
    (username: any, password: any, keepMeloggedIn: boolean, navigate: any) => async (dispatch: any) => {
      try {
        // @ts-ignore
        const oauthUrl = JSON.parse(localStorage.getItem(`${providerRootUrl}-url`));

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("grant_type", "password");

        const res = await fetch(`${oauthUrl}/api/v1/token`, {
          method: "POST",
          body: formData
        });

        const response = await res.json();

        if (response.error) {
          throw new Error("Error");
        } else {
          localStorage.setItem(`${providerRootUrl}-username`, JSON.stringify(username));

          // Backward compatibility: Store cookies with expires date set to -1 to avoid including them to request header
          // TODO: Remove after a period of time
          document.cookie = `${providerRootUrl}-username= ; expires=-1; path=/; Secure`;

          // TODO: SET PLATFORM NAME ON GLOBAL LEVEL (CONFIGURATION FOR GLOBAL VARIABLE) TO REPLACE PLATFORM NAME

          // Store tokenData to localStorage
          const tokenData = {
            token: response.access_token,
            refresh: response.refresh_token
          };
          localStorage.setItem(`${providerRootUrl}-token`, JSON.stringify(tokenData));

          // Backward compatibility: Store cookies with expires date set to -1 to avoid including them to request header
          // TODO: Remove after a period of time
          document.cookie = `${providerRootUrl}-cookie= ; expires=-1; path=/; Secure`;
          document.cookie = `${providerRootUrl}-cookie-refresh= ; expires=-1; path=/; Secure`;

          // keep me logged in checkbox
          if (keepMeloggedIn) {
            // Store expires in a cookie with expires date - keep cookie in browser
            document.cookie = `${providerRootUrl}-expires=${getFutureDateFromCurrentDate(
              30
            )}; expires="${getFutureDateFromCurrentDate(30)}"; path=/; Secure`;
          } else {
            // Store expires in a cookie without expires date
            document.cookie = `${providerRootUrl}-expires=${getFutureDateFromCurrentDate(30)}; path=/; Secure`;
          }

          // redirect to Home page
          navigate(`/${providerRootUrl}/${pagesUrl.dashboard}`);
        }

        dispatch({
          type: loginActionTypes.SET_USER,
          userToken: response.access_token,
          isUserAuthenticated: true
        });
      } catch (err: any) {
        dispatch({
          type: loginActionTypes.TOGGLE_ERROR_MESSAGE,
          errorMessage: "Invalid credentials."
        });
      }
    },
  checkUserLoggedInAction: () => async (dispatch: any) => {
    const accessToken = JSON.parse(localStorage.getItem(`${providerRootUrl}-token`) || "{}")?.token;
    let expiresIn = "";
    if (getCookie(`${providerRootUrl}-expires`)) {
      expiresIn = new Date(getCookie(`${providerRootUrl}-expires`))?.toISOString();
    }

    // If user lands on /login and is already logged in (has accessToken) and if token is valid redirect to Home/Dashboard page
    if (isUserLandsOnLogin(accessToken) && !isTokenExpired(expiresIn)) {
      window.location.pathname = `/${providerRootUrl}/`;
    }

    dispatch({
      type: loginActionTypes.SET_USER,
      userToken: accessToken,
      isUserAuthenticated: !!accessToken // set to true or false here
    });
  },
  logoutUserAction: (navigate: any) => async (dispatch: any) => {
    // Backward compatibility: Store cookies with expires date set to -1 to avoid including them to request header
    // TODO: Remove after a period of time
    document.cookie = `${providerRootUrl}-cookie= ; path=/; expires=-1`;
    document.cookie = `${providerRootUrl}-cookie-refresh= ; path=/; expires=-1`;
    document.cookie = `${providerRootUrl}-username= ; path=/; expires=-1`;

    // delete cookie by setting expires date in the past
    document.cookie = `${providerRootUrl}-expires= ; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    // Delete token data from localStorage
    const tokenData = {
      token: "",
      refresh: ""
    };
    localStorage.setItem(`${providerRootUrl}-token`, JSON.stringify(tokenData));
    // Delete username from localStorage
    localStorage.setItem(`${providerRootUrl}-username`, JSON.stringify(""));

    dispatch({
      type: loginActionTypes.SET_USER,
      userToken: null,
      isUserAuthenticated: null
    });
    dispatch({
      type: loginActionTypes.TOGGLE_ERROR_MESSAGE,
      errorMessage: null
    });

    // redirect to login page
    navigate(`/${providerRootUrl}/${pagesUrl.login}`);
  }
};
