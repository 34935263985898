import CustomButtonStep from "../../../components/CustomButtonStep";
//helpers
import { offersSteps, shouldShowStepCheckmarkIcon } from "../offersHelpers";

const AddEditHeader = props => {
  const getCustomButtonCustomClasses = step => {
    // disable buttons also when loading is active

    return !props.offerStepLoader && (props.validatedSteps.includes(step) || props.selectedStep === step)
      ? "btn-step--w-sixth"
      : "btn-step--w-sixth btn-step--disabled";
  };

  const renderCheckmark = step => {
    return shouldShowStepCheckmarkIcon(step, props.validatedSteps.includes(step));
  };

  return (
    <div className="w-100">
      <h5>{props.isEdit ? `Edit Offer - ${props.offer?.name}` : `Add New Offer`}</h5>
      <br />
      <div className="hotel-offers__steps">
        {offersSteps?.map((offersStep, index) => (
          <CustomButtonStep
            key={index}
            mainClass="btn-step"
            customClasses={getCustomButtonCustomClasses(offersStep.step)}
            onClick={() => {
              !props.offerStepLoader && props.setSelectedStepAction(offersStep.step, "step");
            }}
            buttonText={offersStep.properStepName}
            index={index + 1}
            isSelected={offersStep.step === props.selectedStep}
            showCheckmarkIcon={renderCheckmark(offersStep.step)}
            //TO DO: add condition for disabling
            isDisabled={false}
          />
        ))}
      </div>
    </div>
  );
};

export default AddEditHeader;
