import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from "react-router-dom";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// Layout
import AppLayout from "../../components/AppLayout";
import AppLayoutAuthenticated from "../../components/AppLayout/AppLayoutAuthenticated";
// Pages
import Login from "../../pages/Login";
import ResetPassword from "../../pages/ResetPassword";
import StoreNewPasswordPage from "../../pages/ResetPassword/StoreNewPasswordPage";
import InvalidToken from "../../pages/ResetPassword/InvalidToken";
// Pages Authenticated
import Dashboard from "../../pages/Dashboard/Dashboard";
import Bookings from "../../pages/Bookings";
import Offers from "../../pages/Offers/Offers";
import AddEditOffer from "../../pages/Offers/AddEditOffer/AddEditOffer";
import Logins from "../../pages/Logins";
import AddLogin from "../../pages/Logins/AddLogin";
import EditLogin from "../../pages/Logins/EditLogin";
import ProviderSettings from "../../pages/ProviderSettings";
// Private Protected Routes
import PrivateOffersRoute from "./privateRoutes/PrivateOffersRoute";
import PrivateLoginsRoute from "./privateRoutes/PrivateLoginsRoute";
import PrivateStoreNewPasswordRoute from "./privateRoutes/PrivateStoreNewPasswordRoute";
// Actions
import { loginActionCreators } from "../../pages/Login/loginModule";
// helpers
import { pagesUrl, providerRootUrl } from "../utils/panel-helpers/helpers";

function AppRouter(props: any) {
  useEffect(() => {
    props.checkUserLoggedInAction();
  }, []);

  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route element={<AppLayout />}>
              <Route path={`/${providerRootUrl}/${pagesUrl.login}`} element={<Login />} />
              <Route path={`/${providerRootUrl}/${pagesUrl.resetPassword}`} element={<ResetPassword />} />
              <Route
                path={`/${providerRootUrl}/${pagesUrl.storeNewPassword}`}
                element={
                  <PrivateStoreNewPasswordRoute>
                    <StoreNewPasswordPage />
                  </PrivateStoreNewPasswordRoute>
                }
              />
              <Route path={`/${providerRootUrl}/${pagesUrl.invalidToken}`} element={<InvalidToken />} />
            </Route>

            {/* Authenticated routes */}
            <Route element={<AppLayoutAuthenticated />}>
              <Route path={`/${providerRootUrl}/${pagesUrl.dashboard}`} element={<Dashboard />} />
              <Route path={`/${providerRootUrl}/${pagesUrl.bookings}`} element={<Bookings />} />
              <Route>
                <Route path={`/${providerRootUrl}/${pagesUrl.offers}`} element={<Offers />} />
                <Route path={`/${providerRootUrl}/${pagesUrl.newOffer}`} element={<AddEditOffer />} />
                <Route
                  path={`/${providerRootUrl}/${pagesUrl.editOffer}`}
                  element={
                    <PrivateOffersRoute>
                      <AddEditOffer />
                    </PrivateOffersRoute>
                  }
                />
              </Route>
              <Route>
                <Route path={`/${providerRootUrl}/${pagesUrl.logins}`} element={<Logins />} />
                <Route path={`/${providerRootUrl}/${pagesUrl.addLogins}`} element={<AddLogin />} />
                <Route
                  path={`/${providerRootUrl}/${pagesUrl.editLogins}`}
                  element={
                    <PrivateLoginsRoute>
                      <EditLogin />
                    </PrivateLoginsRoute>
                  }
                />
              </Route>
              <Route path={`/${providerRootUrl}/${pagesUrl.settings}`} element={<ProviderSettings />} />
            </Route>
          </Route>
        )
      )}
    />
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      checkUserLoggedInAction: loginActionCreators.checkUserLoggedInAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
