// Helpers
import ApiHelper from "../../utils/apiHelper";
import { providerRootUrl, pagesUrl, removeTrailingSlash } from "../../utils/panel-helpers/helpers";
// Actions
import { globalActionCreators } from "./globalModule";

export const bookingsActionTypes = {
  CALL_BOOKINGS: "CALL_BOOKINGS",
  CALL_BOOKINGS_WITH_PAGINATION: "CALL_BOOKINGS_WITH_PAGINATION",
  FILTER_BOOKINGS: "FILTER_BOOKINGS",
  SET_NO_BOOKINGS_DATA: "SET_NO_BOOKINGS_DATA"
};

export const initialState = {
  allBookings: [],
  filteredBookings: null,
  noBookingsData: false,
  numberItemPerPage: 0,
  totalNumber: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case bookingsActionTypes.CALL_BOOKINGS:
      return {
        ...state,
        allBookings: action.allBookings,
        filteredBookings: action.allBookings
      };
    case bookingsActionTypes.CALL_BOOKINGS_WITH_PAGINATION:
      return {
        ...state,
        allBookings: action.allBookings,
        filteredBookings: action.allBookings,
        currentPage: action.currentPage,
        numberItemPerPage: action.numberItemPerPage,
        totalNumber: action.totalNumber
      };
    case bookingsActionTypes.FILTER_BOOKINGS:
      return {
        ...state,
        filteredBookings: action.filteredBookings
      };
    case bookingsActionTypes.SET_NO_BOOKINGS_DATA:
      return {
        ...state,
        noBookingsData: action.noBookingsData
      };

    default:
      return state;
  }
};

export const bookingsActionCreators = {
  setPaginationBookingsPageAction: currentPage => async (dispatch, getState) => {
    dispatch(globalActionCreators.toggleGlobalLoaderAction(true));
    dispatch({
      type: bookingsActionTypes.SET_NO_BOOKINGS_DATA,
      noBookingsData: false
    });

    const startRange = getState().home.dateRangeSelection.startDate;
    const endRange = getState().home.dateRangeSelection.endDate;

    try {
      const res = await ApiHelper.get(
        `/v1/bookings/${startRange.format("DD-MM-YYYY")}/${endRange.format("DD-MM-YYYY")}` +
          `${currentPage !== 1 ? `?page=${currentPage}` : ""}`
      );

      let data = res.data;
      dispatch({
        type: bookingsActionTypes.CALL_BOOKINGS_WITH_PAGINATION,
        allBookings: data.bookings,
        filteredBookings: data.bookings,
        currentPage: data.currentPage,
        numberItemPerPage: data.numberItemPerPage,
        totalNumber: data.totalNumber
      });

      dispatch({
        type: bookingsActionTypes.SET_NO_BOOKINGS_DATA,
        noBookingsData: true
      });

      dispatch(globalActionCreators.toggleGlobalLoaderAction(false));
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    }
  },

  loadBookingsAction: (selectedDateRange, currentPage) => async (dispatch, getState) => {
    // trigger Global Loader while loading Bookings data only if Current Page is Bookings Page (/bookings)
    const bookingsUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}/${pagesUrl.bookings}`);
    if (currentPage === bookingsUrlWithoutSlash) {
      dispatch(globalActionCreators.toggleGlobalLoaderAction(true));
    }

    dispatch({
      type: bookingsActionTypes.SET_NO_BOOKINGS_DATA,
      noBookingsData: false
    });

    try {
      const res = await ApiHelper.get(
        `/v1/bookings/` +
          selectedDateRange.startDate.format("DD-MM-YYYY") +
          `/` +
          selectedDateRange.endDate.format("DD-MM-YYYY")
      );

      let data = res.data;
      dispatch({
        type: bookingsActionTypes.CALL_BOOKINGS_WITH_PAGINATION,
        allBookings: data.bookings,
        currentPage: data.currentPage,
        numberItemPerPage: data.numberItemPerPage,
        totalNumber: data.totalNumber
      });

      dispatch({
        type: bookingsActionTypes.SET_NO_BOOKINGS_DATA,
        noBookingsData: true
      });

      // turn off Global Loader after loading Bookings data only if Current Page is Bookings Page (/bookings)
      if (currentPage === bookingsUrlWithoutSlash) {
        dispatch(globalActionCreators.toggleGlobalLoaderAction(false));
      }
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    }
  }
};
