import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// helpers
import { providerRootUrl, pagesUrl } from "../../utils/panel-helpers/helpers";

const PrivateLoginsRoute = ({ children }: any) => {
  // states
  const logins = useSelector((state: any) => state.logins.logins);

  const isEditLoginsAvailable = logins?.length > 0;

  if (isEditLoginsAvailable) {
    return children;
  } else {
    return <Navigate to={`/${providerRootUrl}/${pagesUrl?.logins}`} />;
  }
};

export default PrivateLoginsRoute;
