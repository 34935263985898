import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// TODO: Check should this be implemented on Provider as on Hotel/Business Panel
// import { useTheme } from "@mui/material/styles";
import moment from "moment";

const options = {
  scales: {
    yAxes: [
      {
        id: "A",
        type: "linear",
        position: "left"
      },
      {
        id: "B",
        type: "linear",
        position: "right"
      }
    ]
  },
  events: ["click"],
  legend: {
    position: "top",
    labels: {
      boxWidth: 15,
      fontSize: 10,
      fontFamily: "Neutraface2Book"
    }
  }
};

const GraphBookingsDevProvider = props => {
  // TODO: Check should this be implemented on Provider as on Hotel/Business Panel
  //   const theme = useTheme();

  const [labels, setLabels] = useState([]);
  const [dataBookings, setDataBookings] = useState([]);
  const [dataExpenses, setDataExpenses] = useState([]);

  useEffect(() => {
    developmentOfBookingsGraph();
  }, [props.mainGraphData]);

  const developmentOfBookingsGraph = () => {
    const startDate = props.dateRangeSelection.startDate;
    const endDate = props.dateRangeSelection.endDate;

    // Prepare graph options and data
    let dataBookings = [];
    let dataExpenses = [];
    let labels = [];
    let devBookings = [];

    let loopDate = startDate;
    while (loopDate <= endDate) {
      let newBooking = {};

      newBooking = {
        date: moment(loopDate).format("DD.MM.YYYY"),
        totalBookings: 0,
        totalExpenses: 0
      };

      devBookings.push(newBooking);
      loopDate = moment(loopDate).add(1, "days");
    }

    for (let booking of props.mainGraphData) {
      for (let devBooking of devBookings) {
        if (moment(booking.date).format("DD.MM.YYYY") === devBooking.date) {
          devBooking.totalBookings = booking.bookings;
          devBooking.totalExpenses = Number(booking.revenue).toFixed(2);
        }
      }
    }

    for (let booking of devBookings) {
      labels.push(booking.date);
      dataExpenses.push(booking.totalExpenses);
      dataBookings.push(booking.totalBookings);
    }
    setLabels(labels);
    setDataExpenses(dataExpenses);
    setDataBookings(dataBookings);
  };

  let data = {
    labels: labels,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "#D1E5F5",
        data: dataExpenses,
        order: 1,
        yAxisID: "A"
      },
      {
        label: "Bookings",
        backgroundColor: "rgba(107, 179, 170, 0.5)",
        data: dataBookings,
        type: "line",
        order: 3,
        yAxisID: "B"
      }
    ]
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default GraphBookingsDevProvider;
