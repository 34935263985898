import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// components
import LoginOverlay from "../../components/LoginOverlay";
// screens
import ResetMyPassword from "../../components/ForgotPassword/screens/ResetMyPassword";
import BackToLogin from "../../components/ForgotPassword/screens/BackToLogin";
// helpers
import { providerRootUrl, pagesUrl } from "../../common/utils/panel-helpers/helpers";
// actions
import { useActions } from "../../common/hooks/useActions";
import { resetPasswordActionCreators } from "./resetPasswordModule";

export default function ResetPassword() {
  const [activeScreen, setActiveScreen] = useState("main"); // main || backToLogin

  const navigate = useNavigate();

  // actions
  const sendEmailForResetPassword = useActions(resetPasswordActionCreators?.sendEmailForResetPasswordAction, []);

  // states
  const sendEmailApiError = useSelector((state: any) => state.resetPassword.sendEmailApiError);

  const onResetMyPassword = (email: string) => {
    sendEmailForResetPassword(email, setActiveScreen);
  };

  const renderActiveSection = () => {
    switch (activeScreen) {
      case "main":
        return <ResetMyPassword onResetMyPassword={onResetMyPassword} apiError={sendEmailApiError} />;
      case "backToLogin":
        return <BackToLogin onBackToLoginClick={() => navigate(`/${providerRootUrl}/${pagesUrl.login}`)} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="reset-password">
      <LoginOverlay
        backgroundImageClass="provider-login-page"
        form={renderActiveSection()}
        infoSection={
          <div className="reset-password__info-section">
            <div className="big-title">RESET</div>
            <div className="small-title">PASSWORD</div>
          </div>
        }
      />
    </div>
  );
}
