import { parseISO, getTime, format } from "date-fns";

import { panelUrl } from "@gl-shared/shared/helpers";

export const appSection = {
  dashboard: "Dashboard",
  bookings: "Bookings",
  logins: "Logins",
  addLoginForm: "AddLoginForm",
  editLoginForm: "EditLoginForm",
  providerSettings: "ProviderSettings",
  offers: "Offers",
  editOffer: "EditOffer",
  addOffer: "AddOffer"
};

export const providerRootUrl = "provider";

export const pagesUrl = {
  login: "login/",
  resetPassword: "reset-password/",
  storeNewPassword: `reset-password/store-new-password/:token`,
  invalidToken: "reset-password/invalid-token/",
  dashboard: "",
  bookings: "bookings/",
  offers: "offers/",
  editOffer: "offers/edit/:code/",
  newOffer: "offers/new/",
  logins: "logins/",
  addLogins: "logins/add-login/",
  editLogins: "logins/edit-login/",
  settings: "settings/"
};

export const removeTrailingSlash = str => {
  return str.replace(/\/$/, "");
};

export const delayForHidingFormMsg = 6000;

export const isAddEditOfferPageActive = currentPath => {
  // check if current path is /new or /edit Offer
  return !!(
    currentPath.includes(`/${providerRootUrl}/${pagesUrl.newOffer}`) ||
    currentPath.includes(`/${providerRootUrl}/${pagesUrl.editOffer.split(":")[0]}`)
  );
};

// if min or max amount didn't pass -> use default value (1 & 10)
export const getOptionsToSelect = (minAmount = 1, maxAmount = 10) => {
  let arr = [];
  for (let i = minAmount; i < maxAmount + 1; i++) {
    arr.push({ value: i, label: i.toString() });
  }
  return arr;
};

export const checkRedirection = responseURL => {
  if (responseURL && responseURL.includes(`${panelUrl}/login`)) {
    window.location = responseURL;
  }
};

export const generateCsvString = (titleArr, valueArr) => {
  return [titleArr, ...valueArr].map(f => f.join(",")).join("\n");
};

export const exportCsv = (titleArr, valueArr, csvFilename) => {
  const csv = generateCsvString(titleArr, valueArr);

  const csvContent = "data:text/csv;charset=utf-8," + csv;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", csvFilename);
  document.body.appendChild(link);
  link.click();
};

export const getExportFilename = (suffix, dateRange, isRange) => {
  let formattedDate;
  isRange
    ? (formattedDate = `${dateRange?.startDate?.format("YYYYMMDD")}-${dateRange?.endDate?.format("YYYYMMDD")}`)
    : (formattedDate = `${dateRange?.format("YYYYMMDD")}`);

  return `${suffix}${formattedDate}`;
};

export const exportProviderBookingsCsv = (bookings, currency, hotelName, filename) => {
  const titles = [
    "Booking Code",
    "Booking Date",
    "Booking For Date",
    "Offer",
    "Total units",
    "Customer",
    "Total Price",
    "Commission",
    "Status"
  ];
  const bookingsValueArr = bookings.map(booking => [
    booking?.booking_code,
    format(new Date(booking?.created_at), "dd.MM.yyyy - HH:mm"),
    format(new Date(booking?.event_date), "dd.MM.yyyy") + " - " + booking?.event_time,
    booking?.event?.name,
    booking?.amount_of_units,
    booking?.external_customer_name === hotelName || booking?.external_customer_name === null
      ? booking?.customer.first_name + " " + booking?.customer.last_name
      : booking?.external_customer_name,
    currency + " " + booking?.total_price_without_fees,
    booking?.commission_booking?.total_provider_commission
      ? `${currency} ${booking?.commission_booking?.total_provider_commission.toFixed(2)}`
      : "-",
    booking?.status
  ]);

  return exportCsv(titles, bookingsValueArr, `${filename}.csv`);
};

// Method for handling section loading in globalModule
export const getLoadingSectionArray = (currentSectionLoadingNames, sectionLoadingState, actionSectionLoadingName) => {
  if (sectionLoadingState === true) {
    let updatedArr = [].concat(currentSectionLoadingNames);
    updatedArr.push(actionSectionLoadingName);
    return updatedArr;
  } else if (sectionLoadingState === false) {
    let updatedArr = [].concat(currentSectionLoadingNames);
    updatedArr.splice(updatedArr.indexOf(actionSectionLoadingName), 1);
    return updatedArr;
  }
};

// Get amount of units for offer - as sum of units, for event price type "per_person", or as sum "amount_of_units", for other price types
export const getSumOfUnitsForOffer = booking => {
  let sumOfUnits = 0;
  if (booking.event_price_type === "per_person") {
    sumOfUnits += booking?.number_of_persons + booking?.number_of_children + booking?.number_of_infants;
  } else {
    sumOfUnits += booking?.amount_of_units;
  }
  return sumOfUnits;
};

export const shouldShowRefreshPageIcon = location => {
  const pagesForShowingRefreshIcon = [
    `/${providerRootUrl}/${pagesUrl.dashboard}`,
    `/${providerRootUrl}/${pagesUrl.bookings}`,
    `/${providerRootUrl}/${pagesUrl.offers}`,
    `/${providerRootUrl}/${pagesUrl.logins}`,
    `/${providerRootUrl}/${pagesUrl.settings}`
  ];

  return pagesForShowingRefreshIcon?.includes(location?.pathname);
};

export const getFutureDateFromCurrentDate = days => {
  const today = new Date();
  return new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
};

export const getCookie = cname => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      // Extract and return the JWT token
      return c.substring(name.length, c.length);
    }
  }
  // Return "" if JWT cookie is not found
  return "";
};

// TODO: WRITE TEST
// Check if the token is expired
export const isTokenExpired = expiresIn => {
  if (!expiresIn) {
    return true;
  }
  const dateObject = parseISO(expiresIn);
  const milliseconds = getTime(dateObject);

  return new Date().getTime() > milliseconds;
};
