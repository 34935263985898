// helpers
import { pagesUrl, appSection, providerRootUrl } from "../../utils/panel-helpers/helpers";
import ApiHelper from "../../utils/apiHelper";
// actions
import { globalActionCreators } from "./globalModule";

export const loginsActionTypes = {
  CALL_LOGINS: "CALL_LOGINS",
  SET_SELECTED_LOGIN: "SET_SELECTED_LOGIN"
};

export const initialState = {
  logins: [],
  selectedLogin: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case loginsActionTypes.CALL_LOGINS:
      return {
        ...state,
        logins: action.logins
      };
    case loginsActionTypes.SET_SELECTED_LOGIN:
      return {
        ...state,
        selectedLogin: action.selectedLogin
      };

    default:
      return state;
  }
};

export const loginsActionCreators = {
  setSelectedLoginAction: selectedLogin => {
    return {
      type: loginsActionTypes.SET_SELECTED_LOGIN,
      selectedLogin
    };
  },
  loadLoginsAction: callSectionLoader => async (dispatch, getState) => {
    const sectionLoadingNamesState = getState().global.sectionLoadingNames;
    if (sectionLoadingNamesState?.includes(appSection?.logins)) {
      // disable new API call if we are still waiting for response
      return;
    }

    callSectionLoader(true, appSection?.logins);

    try {
      const res = await ApiHelper.get(`/v1/logins`);

      dispatch({
        type: loginsActionTypes.CALL_LOGINS,
        logins: res?.data
      });
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    } finally {
      callSectionLoader(false, appSection?.logins);
    }
  },
  addLoginsAction:
    (
      loginName,
      loginEmail,
      loginPhone,
      loginManagerAccess,
      loginPassword,
      onHandleFormMessage,
      onLoadLogins,
      navigate
    ) =>
    async dispatch => {
      try {
        let bodyFormData = {
          fullName: loginName,
          email: loginEmail,
          phoneNumber: loginPhone,
          plainPassword: loginPassword
        };

        const res = await ApiHelper.post(`/v1/login/new`, {}, JSON.stringify(bodyFormData));

        onHandleFormMessage(res.data.status, "AddLogin");
        onLoadLogins();
        navigate(`/${providerRootUrl}/${pagesUrl.logins}`);
      } catch (error) {
        console.log(error);
      }
    },
  updateLoginsAction:
    (
      loginName,
      loginEmail,
      loginPhone,
      loginManagerAccess,
      loginPassword,
      onHandleFormMessage,
      onLoadLogins,
      navigate
    ) =>
    async (dispatch, getState) => {
      const selectedLoginIdState = getState().logins.selectedLogin.id;

      try {
        let bodyFormData = {
          fullName: loginName,
          email: loginEmail,
          phoneNumber: loginPhone,
          plainPassword: loginPassword
        };

        const res = await ApiHelper.post(`/v1/login/edit/` + selectedLoginIdState, {}, JSON.stringify(bodyFormData));

        onHandleFormMessage(res.data.status, "EditLogin");
        onLoadLogins();
        navigate(`/${providerRootUrl}/${pagesUrl.logins}`);
      } catch (error) {
        console.log(error);
      }
    },
  deleteLoginAction: (onHandleFormMessage, onLoadLogins, navigate) => async (dispatch, getState) => {
    const selectedLoginIdState = getState().logins.selectedLogin.id;

    try {
      const res = await ApiHelper.get(`/v1/login/remove/` + selectedLoginIdState);

      onHandleFormMessage(res.data.status, "DeleteLogin");
      onLoadLogins();
      navigate(`/${providerRootUrl}/${pagesUrl.logins}`);
    } catch (error) {
      console.log(error);
    }
  }
};
