const LoginButton = ({
  type = "submit",
  isDisabled = false,
  mainClass = "login__submit",
  onClick,
  buttonText,
  customClasses
}) => {
  const customBtnClass = customClasses ? customClasses : "";
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={
        isDisabled ? `${mainClass} ${mainClass}--disabled ${customBtnClass}` : `${mainClass} ${customBtnClass}`
      }
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default LoginButton;
