import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// components
// @ts-ignore
import { CustomAlertDialog } from "@gl-shared/shared/ui";
// helpers
import { pagesUrl, providerRootUrl, isAddEditOfferPageActive } from "../../common/utils/panel-helpers/helpers";
import { leaveProcessNoticeMsg } from "../../pages/Offers/offersHelpers";
// actions
import { useActions } from "../../common/hooks/useActions";
import { loginActionCreators } from "../../pages/Login/loginModule";

const activeItemClass = "category__row category__row--active";
const regularItemClass = "category__row";

const AppSidebar = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Alert Dialog data:
  const [pageClickedForDialog, setPageClickedForDialog] = useState("");
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  // END of Alert Dialog data

  // actions
  const logoutUser = useActions(loginActionCreators?.logoutUserAction, []);

  // states
  const hotelUser = useSelector((state: any) => state.home?.hotelData?.hotelUser);
  const isFormChanged = useSelector((state: any) => state.offers.isFormChanged);

  const onSidebarItemClick = (page: string) => {
    if (isAddEditOfferPageActive(location?.pathname) && isFormChanged) {
      setPageClickedForDialog(page);
      setIsAlertDialogOpen(true);

      return;
    }

    navigate(`/${providerRootUrl}/${page}`);
  };

  // Alert Dialog confirmation
  const handleSidebarItemConfirmation = () => {
    setIsAlertDialogOpen(false);
    navigate(`/${providerRootUrl}/${pageClickedForDialog}`);
  };

  // Alert Dialog cancellation
  const handleCloseAlertDialog = () => setIsAlertDialogOpen(false);

  // logout action
  const onLogoutClick = () => logoutUser(navigate);

  return (
    <div className="navbar-default navbar-static-side" role="navigation">
      <CustomAlertDialog
        isOpen={isAlertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleBtnLeft={handleCloseAlertDialog}
        handleBtnRight={handleSidebarItemConfirmation}
        title={"CHANGES NOT SAVED"}
        content={leaveProcessNoticeMsg}
        btnLeftLabel={"GO BACK"}
        btnRightLabel={"LEAVE ANYWAY"}
      />
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <div className="hotel-panel__profile">
            <span className="hotel-panel__profile-label">Provider Panel</span>
            <span className="hotel-panel__profile-name">{hotelUser}</span>
          </div>
          <div className="categories__area">
            <div
              onClick={() => {
                onSidebarItemClick(pagesUrl?.dashboard);
              }}
              className={location?.pathname === `/${providerRootUrl}/` ? activeItemClass : regularItemClass}
            >
              <div className="category__text">Dashboard</div>
            </div>
            <div
              onClick={() => {
                onSidebarItemClick(pagesUrl?.bookings);
              }}
              className={location?.pathname?.includes(pagesUrl?.bookings) ? activeItemClass : regularItemClass}
            >
              <div className="category__text">Bookings</div>
            </div>
            <div
              onClick={() => {
                onSidebarItemClick(pagesUrl?.offers);
              }}
              className={location?.pathname?.includes(pagesUrl?.offers) ? activeItemClass : regularItemClass}
            >
              <div className="category__text">Offers</div>
            </div>
            <div
              onClick={() => {
                onSidebarItemClick(pagesUrl?.logins);
              }}
              className={location?.pathname?.includes(pagesUrl?.logins) ? activeItemClass : "category__row"}
            >
              <div className="category__text">Logins</div>
            </div>
            <div
              onClick={() => {
                onSidebarItemClick(pagesUrl?.settings);
              }}
              className={location?.pathname?.includes(pagesUrl?.settings) ? activeItemClass : "category__row"}
            >
              <div className="category__text">Settings</div>
            </div>
            <div className="hotel__logout" onClick={onLogoutClick}>
              <div className={regularItemClass}>
                <div className="category__text">Logout</div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default AppSidebar;
