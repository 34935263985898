import { combineReducers } from "redux";

// Reducers:
import globalAppReducer from "./modules/globalModule";
import loginReducer from "../../pages/Login/loginModule";
import homeReducer from "./modules/homeModule";
import settingsReducer from "./modules/settingsModule";
import bookingsReducer from "./modules/bookingsModule";
import dashboardReducer from "./modules/dashboardModule";
import loginsReducer from "./modules/loginsModule";
import offersReducer from "./modules/offersModule";
import errorFieldReducer from "./modules/errorFieldModule";
import resetPasswordModule from "../../pages/ResetPassword/resetPasswordModule";

const rootReducer = combineReducers({
  global: globalAppReducer,
  login: loginReducer,
  home: homeReducer,
  settings: settingsReducer,
  bookings: bookingsReducer,
  dashboard: dashboardReducer,
  logins: loginsReducer,
  offers: offersReducer,
  errorField: errorFieldReducer,
  resetPassword: resetPasswordModule
});

export default rootReducer;
