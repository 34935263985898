import { useState } from "react";
// components
import LoginOverlay from "../../../LoginOverlay";

interface StoreNewPasswordTypes {
  saveResetPassword: any;
  savePasswordApiError: boolean;
  navigate: any;
  backgroundImageClass: string;
}

export default function StoreNewPassword({
  saveResetPassword,
  savePasswordApiError,
  navigate,
  backgroundImageClass
}: StoreNewPasswordTypes) {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [newPassInputError, setNewPassInputError] = useState(false);
  const [repeatPassInputError, setRepeatPassInputError] = useState(false);

  const handleSaveMyPassword = async () => {
    if (newPassword?.length < 8) {
      setNewPassInputError(true);
      setRepeatPassInputError(false);
    } else if (newPassword !== repeatPassword) {
      setNewPassInputError(false);
      setRepeatPassInputError(true);
    } else if (repeatPassword?.length < 8) {
      setNewPassInputError(false);
      setRepeatPassInputError(true);
    } else {
      setNewPassInputError(false);
      setRepeatPassInputError(false);

      saveResetPassword(newPassword, repeatPassword, navigate);
    }
  };

  const handleChangeNewPassword = (event: any) => {
    setNewPassword(event.target.value);
  };

  const handleChangeRepeatPassword = (event: any) => {
    setRepeatPassword(event.target.value);
  };

  const handleKeypress = (event: any) => {
    if (event.key === "Enter") {
      handleSaveMyPassword();
    }
  };

  return (
    <div className="reset-password">
      <LoginOverlay
        backgroundImageClass={backgroundImageClass}
        form={
          <div className="screen-wrapper">
            {savePasswordApiError && (
              <div className="text-instructions--warning">
                Something went wrong, please try again later.
                <br /> <br />
              </div>
            )}
            <div className="text-instructions">
              Great, we’re almost done.
              <br /> <br />
              Please define a new password for your account below. Please note that your password needs to have at least
              8 characters.
            </div>
            <div className="reset-password-email-input-wrapper">
              <input
                type="password"
                className={`login__input ${newPassInputError && "input-error"}`}
                placeholder="New Password"
                onChange={handleChangeNewPassword}
                value={newPassword}
                onKeyPress={handleKeypress}
              />
              <div className="separator-div" />
              <input
                type="password"
                className={`login__input ${repeatPassInputError && "input-error"}`}
                placeholder="Repeat Password"
                onChange={handleChangeRepeatPassword}
                value={repeatPassword}
                onKeyPress={handleKeypress}
              />
            </div>
            <button className="reset-password-submit-button" type="submit" onClick={handleSaveMyPassword}>
              SAVE MY PASSWORD
            </button>
          </div>
        }
        infoSection={
          <div className="reset-password__info-section">
            <div className="big-title">RESET</div>
            <div className="small-title">PASSWORD</div>
          </div>
        }
      />
    </div>
  );
}
