import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
// helpers
import { pagesUrl, providerRootUrl } from "../../common/utils/panel-helpers/helpers";
import { checkForEmptyField, checkForValidEmail } from "../../alias/helpers";
// actions
import { loginsActionCreators } from "../../common/store/modules/loginsModule";
// components
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomButton } from "@gl-shared/shared/ui";
import { CustomInput } from "@gl-shared/shared/ui";

const AddLogin = props => {
  const [loginName, setLoginName] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPhone, setLoginPhone] = useState("433434342");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorField, setErrorField] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    password: false
  });

  const navigate = useNavigate();
  const outletContext = useOutletContext();

  const handleSubmit = async () => {
    let errorCheck = true;
    let errorField = {
      name: false,
      email: false,
      phoneNumber: false,
      password: false
    };

    if (loginName === "") {
      errorCheck = false;
      errorField.name = true;
    }
    if (checkForEmptyField(loginEmail) || checkForValidEmail(loginEmail)) {
      errorCheck = false;
      errorField.email = true;
    }
    if (loginPhone === "") {
      errorCheck = false;
      errorField.phoneNumber = true;
    }
    if (loginPassword.length < 8) {
      errorCheck = false;
      errorField.password = true;
    }

    setErrorField(errorField);

    if (errorCheck === false) {
      return false;
    }

    props.addLoginsAction(
      loginName,
      loginEmail,
      loginPhone,
      false,
      loginPassword,
      outletContext.handleFormMessage,
      outletContext.loadLogins,
      navigate
    );
  };

  return (
    <div className="employees component">
      <div className="row">
        <div className="col-lg-6">
          <div className="ibox form__ibox">
            <div className="ibox-title">
              <h5>Add New Login</h5>
            </div>
            <div className="ibox-content">
              <div className="hotel__form">
                <CustomFormInput
                  showTooltip={false}
                  name="loginName"
                  id="name"
                  label="Name"
                  onInputChange={event => setLoginName(event.target.value)}
                  errorField={errorField}
                  isRequired={true}
                  value={loginName}
                />
                <CustomFormInput
                  showTooltip={false}
                  name="loginEmail"
                  id="email"
                  label="E-mail"
                  onInputChange={event => setLoginEmail(event.target.value)}
                  errorField={errorField}
                  isRequired={true}
                  value={loginEmail}
                />
                <CustomFormInput
                  maskedNumberInput={true}
                  maskedPhoneNumber={true}
                  showTooltip={false}
                  name="loginPhone"
                  id="phoneNumber"
                  label="Phone Number"
                  errorField={errorField}
                  onInputBlur={value => setLoginPhone(value)}
                  isRequired={true}
                  value={loginPhone}
                />
                <div className="form-group  row">
                  <label className="col-sm-4 col-form-label field--required">Password</label>
                  <div className="col-sm-8 pw-field__container">
                    <CustomInput
                      name="loginPassword"
                      id="password"
                      type="password"
                      errorField={errorField}
                      onInputChange={event => setLoginPassword(event.target.value)}
                      value={loginPassword}
                      isRequired={true}
                      showLabel={false}
                    />
                    <div className="pw-field__message">min. 8 characters</div>
                  </div>
                </div>
                <div className="form-group  row">
                  <div className="left-side"></div>
                  <div className="right-side">
                    <CustomButton
                      isDisabled={false}
                      mainClass="btn-gray"
                      customClasses="btn-gray--height-l"
                      onClick={() => {
                        navigate(`/${providerRootUrl}/${pagesUrl.logins}`);
                      }}
                      buttonText="CANCEL"
                    />
                    <CustomButton
                      isDisabled={false}
                      mainClass="btn-green"
                      customClasses="btn-green--height-l"
                      onClick={handleSubmit}
                      buttonText="SAVE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addLoginsAction: loginsActionCreators.addLoginsAction
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AddLogin);
