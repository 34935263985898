import axios, { AxiosInstance, AxiosResponse } from "axios";
// helpers
import { pagesUrl, providerRootUrl, getFutureDateFromCurrentDate } from "./panel-helpers/helpers";

const appHeaders = {
  "Content-Type": "application/json"
};

export let axiosInstance: any;

let isRefreshing = false;

let refreshQueue: (() => void)[] = [];

// Dynamically generate a timestamp for cache-busting
const timestamp = new Date().getTime();

const createQueryString = (params: any) => {
  const paramsKeys = Object.keys(params);
  let queryString = "";
  paramsKeys.forEach((key, index) => {
    if (Array.isArray(params[key])) {
      if (params[key].length > 0) {
        queryString += index === 0 ? "?" : "&";
        params[key].forEach((el: any, i: any) => {
          if (i > 0) {
            queryString += "&";
          }
          queryString += `${encodeURIComponent(`${key}[${i}]`)}=${encodeURIComponent(el)}`;
        });
      }
    } else {
      queryString += index === 0 ? "?" : "&";
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }
  });
  return queryString;
};

class ApiHelper {
  static axiosInstance: AxiosInstance | null = null;
  static requestQueue: (() => void)[] = [];

  static async setAxiosInstance(instance: AxiosInstance) {
    this.axiosInstance = instance;
    // Release queued requests once Axios instance is ready
    this.executeQueuedRequests();
  }

  static async executeQueuedRequests() {
    // Iterate over the requestQueue and execute each queued request
    this.requestQueue.forEach(request => request());
    // Clear the queue after executing all requests
    this.requestQueue = [];
  }

  static async get<T>(
    resourceUrl: string,
    params: any = {},
    additionalHeaders?: object,
    responseType?: any
  ): Promise<AxiosResponse<T>> {
    const url = `${resourceUrl + createQueryString(params)}`;
    const requestConfig = {
      method: "get",
      url,
      withCredentials: false,
      headers: {
        ...appHeaders,
        ...additionalHeaders
      },
      responseType: responseType
    };

    if (!this.axiosInstance) {
      // Queue the request if Axios instance is not ready
      return new Promise((resolve, reject) => {
        this.requestQueue.push(() => {
          resolve(this.axiosInstance!.request<T>(requestConfig));
        });
      });
    }

    // If Axios instance is ready, send the request immediately
    return this.axiosInstance.request<T>(requestConfig);
  }

  static async post<T>(
    resourceUrl: string,
    params: any = {},
    bodyData: any,
    customHeaders?: any
  ): Promise<AxiosResponse<T>> {
    const url = `${resourceUrl + createQueryString(params)}`;

    const requestConfig = {
      method: "post",
      url,
      data: bodyData,
      headers: customHeaders
        ? { ...customHeaders }
        : {
            ...appHeaders
          }
    };

    if (!this.axiosInstance) {
      // Queue the request if Axios instance is not ready
      return new Promise((resolve, reject) => {
        this.requestQueue.push(() => {
          resolve(this.axiosInstance!.request<T>(requestConfig));
        });
      });
    }

    // If Axios instance is ready, send the request immediately
    return this.axiosInstance.request<T>(requestConfig);
  }

  static async put<T>(resourceUrl: string, bodyData: any): Promise<AxiosResponse<T>> {
    const url = `${resourceUrl}`;
    const requestConfig = {
      method: "put",
      url,
      data: bodyData,
      headers: appHeaders
    };

    if (!this.axiosInstance) {
      // Queue the request if Axios instance is not ready
      return new Promise((resolve, reject) => {
        this.requestQueue.push(() => {
          resolve(this.axiosInstance!.request<T>(requestConfig));
        });
      });
    }

    // If Axios instance is ready, send the request immediately
    return this.axiosInstance.request<T>(requestConfig);
  }

  static async patch<T>(resourceUrl: string, params: any = {}, bodyData: any): Promise<AxiosResponse<T>> {
    const url = `${resourceUrl + createQueryString(params)}`;
    const requestConfig = {
      method: "patch",
      url,
      data: bodyData,
      headers: appHeaders
    };

    if (!this.axiosInstance) {
      // Queue the request if Axios instance is not ready
      return new Promise((resolve, reject) => {
        this.requestQueue.push(() => {
          resolve(this.axiosInstance!.request<T>(requestConfig));
        });
      });
    }

    // If Axios instance is ready, send the request immediately
    return this.axiosInstance.request<T>(requestConfig);
  }

  static async delete<T>(resourceUrl: string): Promise<AxiosResponse<T>> {
    const url = `${resourceUrl}`;
    const requestConfig = { method: "delete", url, headers: appHeaders };

    if (!this.axiosInstance) {
      // Queue the request if Axios instance is not ready
      return new Promise((resolve, reject) => {
        this.requestQueue.push(() => {
          resolve(this.axiosInstance!.request<T>(requestConfig));
        });
      });
    }

    // If Axios instance is ready, send the request immediately
    return this.axiosInstance.request<T>(requestConfig);
  }
}

async function refreshAccessToken(): Promise<boolean> {
  try {
    const refreshToken = JSON.parse(localStorage.getItem(`${providerRootUrl}-token`) || "{}")?.refresh;
    // @ts-ignore
    const cookieUrl = JSON.parse(localStorage.getItem(`${providerRootUrl}-url`));
    // @ts-ignore
    const username = JSON.parse(localStorage.getItem(`${providerRootUrl}-username`));
    // @ts-ignore
    const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));

    // Make a request to refresh the access token
    const formData = new FormData();
    formData.append("client_id", username);
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);

    const res = await fetch(`${cookieUrl}/api/v1/refresh-token`, {
      method: "POST",
      body: formData
    });

    const responseToken = await res.json();

    // Store tokenData to localStorage
    const tokenData = {
      token: responseToken.access_token,
      refresh: responseToken.refresh_token
    };
    localStorage.setItem(`${providerRootUrl}-token`, JSON.stringify(tokenData));

    // check keep me logged in checkbox
    if (loggedIn) {
      // Store expires in a cookie with expires date - keep cookie in browser
      document.cookie = `${providerRootUrl}-expires=${getFutureDateFromCurrentDate(
        30
      )}; expires="${getFutureDateFromCurrentDate(30)}"; path=/; Secure`;
    } else {
      // Store expires in a cookie without expires date
      document.cookie = `${providerRootUrl}-expires=${getFutureDateFromCurrentDate(30)}; path=/; Secure`;
    }

    // Backward compatibility: Store cookies with expires date set to -1 to avoid including them to request header
    // TODO: Remove after a period of time
    document.cookie = `${providerRootUrl}-cookie= ; path=/; expires=-1`;
    document.cookie = `${providerRootUrl}-cookie-refresh= ; expires=-1; path=/; Secure`;

    // Return true to indicate successful token refresh
    return true;
  } catch (error: any) {
    // Handle error while refreshing token
    console.error("Error refreshing access token:", error);

    // Backward compatibility: Store cookies with expires date set to -1 to avoid including them to request header
    // TODO: Remove after a period of time
    document.cookie = `${providerRootUrl}-cookie= ; path=/; expires=-1`;
    document.cookie = `${providerRootUrl}-cookie-refresh= ; path=/; expires=-1`;
    document.cookie = `${providerRootUrl}-username= ; path=/; expires=-1`;

    // delete cookie by setting expires date in the past
    document.cookie = `${providerRootUrl}-expires= ; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    const tokenData = {
      token: "",
      refresh: ""
    };

    localStorage.setItem(`${providerRootUrl}-token`, JSON.stringify(tokenData));
    localStorage.setItem(`${providerRootUrl}-username`, JSON.stringify("/"));

    window.location.pathname = `/${providerRootUrl}/${pagesUrl.login}`;
  } finally {
    isRefreshing = false;
    // Execute the queued requests
    refreshQueue.forEach(resolve => resolve());
    refreshQueue = [];
    return true;
  }
}

// Configure Axios and set up interceptors
async function configureAxios() {
  try {
    const response = await axios.get(`${window.location.origin}/${providerRootUrl}/config.json?v=${timestamp}`);

    const axiosInstance = axios.create({
      baseURL: response.data.api_url,
      withCredentials: false
    });

    localStorage.setItem(`${providerRootUrl}-url`, JSON.stringify(response.data.oauth_url));

    document.cookie = `${providerRootUrl}-cookie-url= ; expires=-1; path=/; Secure`;

    // Add a request interceptor to add the access token to each request
    axiosInstance.interceptors.request.use(
      config => {
        // Retrieve the access token from cookie
        const accessToken = JSON.parse(localStorage.getItem(`${providerRootUrl}-token`) || "{}")?.token;

        // Add the access token to the request headers
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor to handle token expiration
    axiosInstance.interceptors.response.use(
      response => {
        // Return a successful response directly
        return response;
      },
      async error => {
        if (error.response?.status === 403) {
          let refreshed = false;

          // Unauthorized error, attempt to refresh the access token
          if (!isRefreshing) {
            // Set isRefreshing to true to prevent multiple token refresh calls
            isRefreshing = true;

            // Create a promise for the original request
            const retryOriginalRequest = new Promise(resolve => {
              // Queue the original request to be retried after token refresh
              refreshQueue.push(() => {
                resolve(axiosInstance(error.config));
              });
            });

            // Call the refreshAccessToken function
            refreshed = await refreshAccessToken();

            if (refreshed) {
              return retryOriginalRequest;
            }
          }

          // Create and return promise for the original request
          return new Promise(resolve => {
            // Queue the original request to be retried after token refresh
            refreshQueue.push(() => {
              resolve(axiosInstance(error.config));
            });
          });
        }
        // For other errors, return the error
        return Promise.reject(error);
      }
    );

    // Configure the Axios instance using ApiHelper
    await ApiHelper.setAxiosInstance(axiosInstance);
  } catch (error) {
    console.error("Error configuring Axios:", error);
  }
}

// Call the function to configure Axios
configureAxios();

export default ApiHelper;
