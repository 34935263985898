import { useState } from "react";
import { useNavigate } from "react-router-dom";
// helpers
import { pagesUrl, providerRootUrl } from "../../../common/utils/panel-helpers/helpers";
import { leaveProcessNoticeMsg, offersStep } from "../offersHelpers";
// components
import { CustomButton } from "@gl-shared/shared/ui";
import { CustomAlertDialog } from "@gl-shared/shared/ui";

const AddEditButtons = props => {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [isDeleteBtnTriggered, setIsDeleteBtnTriggered] = useState(false);

  const navigate = useNavigate();

  const handleCancelBackButton = () => {
    switch (props.selectedStep) {
      case offersStep.offerDetails:
        // Handle Cancel Button:
        setIsDeleteBtnTriggered(false);
        // if something has changed in form -> show the modal -> otherwise go back immediately
        if (props.isFormChanged) {
          setIsAlertDialogOpen(true);
        } else {
          handleCancelConfirmation();
        }

        break;
      default:
        // Handle Back button:
        const getPrevStepIndex = Object.keys(offersStep).findIndex(step => step === props.selectedStep) - 1;
        props.setSelectedStepAction(Object.keys(offersStep)[getPrevStepIndex], "previous");
        return;
    }
  };

  const handleNextSaveButton = () => {
    switch (props.selectedStep) {
      case offersStep.summary:
        return props.onSave();
      default:
        // Handle Next button:
        const getNextStepIndex = Object.keys(offersStep).findIndex(step => step === props.selectedStep) + 1;
        props.setSelectedStepAction(Object.keys(offersStep)[getNextStepIndex], "next");
        return;
    }
  };

  const renderCancelOrBackButton = () => {
    return (
      <CustomButton
        mainClass="btn-gray"
        customClasses="add-edit-buttons-container__cancel-btn"
        onClick={handleCancelBackButton}
        buttonText={props.selectedStep === offersStep.offerDetails ? "Cancel" : "Back"}
      />
    );
  };

  const renderNextOrSaveButton = () => {
    return (
      <CustomButton
        mainClass="btn-green"
        onClick={handleNextSaveButton}
        buttonText={props.selectedStep === offersStep.summary ? "Save" : "Next"}
      />
    );
  };

  const handleCloseAlertDialog = () => {
    setIsAlertDialogOpen(false);
  };
  const handleDeleteOffer = () => {
    setIsDeleteBtnTriggered(true);
    setIsAlertDialogOpen(true);
  };
  const handleDeleteOfferConfirmation = () => {
    props.onDelete();
    setIsAlertDialogOpen(false);
  };

  const handleCancelConfirmation = () => {
    navigate(`/${providerRootUrl}/${pagesUrl.offers}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="add-edit-buttons-container">
      <CustomAlertDialog
        isOpen={isAlertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleBtnLeft={handleCloseAlertDialog}
        handleBtnRight={!isDeleteBtnTriggered ? handleCancelConfirmation : handleDeleteOfferConfirmation}
        title={!isDeleteBtnTriggered ? "CHANGES NOT SAVED" : "ARE YOU SURE?"}
        content={
          !isDeleteBtnTriggered ? leaveProcessNoticeMsg : "Are you sure you want to permanently delete this offer?"
        }
        btnLeftLabel={!isDeleteBtnTriggered ? "GO BACK" : "CANCEL"}
        btnRightLabel={!isDeleteBtnTriggered ? "LEAVE ANYWAY" : "DELETE"}
      />
      <div>{props.isEdit && <CustomButton mainClass="btn-red" onClick={handleDeleteOffer} buttonText="Delete" />}</div>

      <div>
        {renderCancelOrBackButton()}
        {renderNextOrSaveButton()}
      </div>
    </div>
  );
};

export default AddEditButtons;
