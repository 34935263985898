import { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// component
import AvailabilityTimeslotsPerType from "./AvailabilityTimeslotsPerType";
import { CustomFormDropdown } from "@gl-shared/shared/ui";
import { CustomAlertDialog } from "@gl-shared/shared/ui";
// helpers
import {
  bookingDeadlineOptions,
  changeAvailabilityTimeslotTypeMsg,
  checkAreTimeslotsDataAdded,
  checkForCustomTimeslotType,
  days,
  getFormattedTimeValue,
  minMaxAmountOptions,
  offersStep,
  timeslotsOptions,
  tooltip
} from "../offersHelpers";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";

const availabilityStep = offersStep.availability;
const timeslotAddDisableStatusPerDay = {
  Monday: { isDisabled: false },
  Tuesday: { isDisabled: false },
  Wednesday: { isDisabled: false },
  Thursday: { isDisabled: false },
  Friday: { isDisabled: false },
  Saturday: { isDisabled: false },
  Sunday: { isDisabled: false }
};

const timeslotsCustomSetupMessage1 =
  "This offer is using a custom setup of timeslots, which is why you cannot change the availability yourself.";
const timeslotsCustomSetupMessage2 =
  "If you want to make changes, please reach out to your GET LOCAL representative or send a message to  support@get-local.com.";

const AvailabilityStep = props => {
  const [addTimeslotDisableStatusPerDay, setAddTimeslotDisableStatusPerDay] = useState(timeslotAddDisableStatusPerDay);
  // Dialog Alert data:
  const [timeslotDropdownItemDialog, setTimeslotDropdownItemDialog] = useState(props.availabilityData.event_type.type);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  // If Admin set wrong format of Tymeslots (custom setup of Timeslots) hide Timeslots
  const showTimeslots = !checkForCustomTimeslotType(
    props.availabilityData.event_type.type === "fixed"
      ? props.availabilityData["timeslotsFixedType"]
      : props.availabilityData.event_type.type === "timeslotIntervals"
      ? props.availabilityData["timeslotsIntervalType"]
      : props.availabilityData["timeslotsPerDayType"],
    props.availabilityData.event_type.type
  );

  const handleTimeslotsDropdown = item => {
    // Prevent clicking on the same timeslot type
    if (item?.eventType === props.availabilityData.event_type?.eventType) {
      return null;
    }

    // If there are some added timeslots time --> show alert confirmation message --> if not --> handle click immediately
    const selectedTimeslots =
      props.availabilityData.event_type.eventType === 1
        ? props.availabilityData.timeslotsFixedType
        : props.availabilityData.event_type.eventType === 2
        ? props.availabilityData.timeslotsPerDayType
        : props.availabilityData.timeslotsIntervalType;
    const shouldRenderConfirmAlert = checkAreTimeslotsDataAdded(
      selectedTimeslots,
      props.availabilityData.event_type.type
    );
    if (shouldRenderConfirmAlert) {
      setTimeslotDropdownItemDialog(item);
      setIsAlertDialogOpen(true);
    } else {
      updateAvailabilityStep(item);
    }
    setAddTimeslotDisableStatusPerDay(timeslotAddDisableStatusPerDay);
  };

  const updateAvailabilityStep = item => {
    props.updateStepAction(availabilityStep, { ...props.availabilityData, event_type: item });
    // Reset all timeslots day data to default value:
    props.resetAvailabilityTimeslotsDataAction();
  };

  const renderDay = day => {
    switch (props.availabilityData.event_type.type) {
      case "fixed":
        return props.availabilityData.timeslotsFixedType[day];

      case "timeslotIntervals":
        return props.availabilityData.timeslotsIntervalType[day];

      case "onePerAllDay":
        return props.availabilityData.timeslotsPerDayType[day];
      default:
        return props.availabilityData.timeslotsFixedType[day];
    }
  };

  const getTimeslots = day => {
    switch (props.availabilityData.event_type.type) {
      case "fixed":
        let slotAddedHours;
        let slotAddedMinutes;
        if (props.availabilityData["timeslotsFixedType"][day].length !== 0) {
          const previousSlotValue =
            props.availabilityData["timeslotsFixedType"][day][
              props.availabilityData["timeslotsFixedType"][day].length - 1
            ];
          let previousSlotHours = previousSlotValue.value.split(":")[0];
          let previousSlotMinutes = previousSlotValue.value.split(":")[1];
          if (Number(previousSlotMinutes) + 30 === 60) {
            slotAddedHours = Number(previousSlotHours) + 1;
            slotAddedMinutes = 0;
            if (slotAddedHours > 23) {
              slotAddedHours = 23;
              slotAddedMinutes = 59;
            }
          } else {
            slotAddedHours = Number(previousSlotHours);
            slotAddedMinutes = Number(previousSlotMinutes) + 30;

            if (Number(previousSlotMinutes) === 59) {
              slotAddedHours = 23;
              slotAddedMinutes = 59;
            }
          }
        } else {
          slotAddedHours = 9;
          slotAddedMinutes = 0;
        }
        return [
          ...props.availabilityData.timeslotsFixedType[day],
          {
            value: getFormattedTimeValue(slotAddedHours, slotAddedMinutes),
            label: getFormattedTimeValue(slotAddedHours, slotAddedMinutes)
          }
        ];
      case "timeslotIntervals":
        const increment = props.availabilityData.event_type.increment;
        let slotAddedToMinutes;
        let slotAddedFromMinutes;
        let slotAddedFromHours;
        let slotAddedToHours;
        if (props.availabilityData["timeslotsIntervalType"][day].to.length !== 0) {
          const previousSlotEndValue =
            props.availabilityData["timeslotsIntervalType"][day]["to"][
              props.availabilityData["timeslotsIntervalType"][day].to.length - 1
            ];
          // previous slot End value hours and minutes
          let toHours = previousSlotEndValue.value.split(":")[0];
          let toMinutes = previousSlotEndValue.value.split(":")[1];

          if (Number(toMinutes) + increment === 60) {
            // if minutes of previous slot End value + increment reach 60 minutes then increase hour by 1h

            // set FROM and TO minutes to 00
            slotAddedFromMinutes = 0;
            slotAddedToMinutes = 0;

            // FROM and TO range is 1h
            slotAddedFromHours = Number(toHours) + 1;
            slotAddedToHours = Number(toHours) + 2;

            // if value of added slot FROM hours after increasing previous slot End value hours by 1h is above or equal 23h set added TO hours to 23 and minutes to 59
            if (slotAddedFromHours >= 23) {
              slotAddedToHours = 23;
              slotAddedToMinutes = 59;
            }
            // if value of added slot FROM hours after increasing previous slot End value hours by 1h is 24h set added FROM hours to 23 and minutes to 59
            if (slotAddedFromHours === 24) {
              slotAddedFromHours = 23;
              slotAddedFromMinutes = 59;
            }
          } else {
            // FROM and TO minutes increase by selected type increment
            slotAddedFromMinutes = Number(toMinutes) + increment;
            slotAddedToMinutes = Number(toMinutes) + increment;

            // FROM and TO range is 1h
            slotAddedFromHours = Number(toHours);
            slotAddedToHours = Number(toHours) + 1;

            // if value of added slot TO hours after increasing previous slot End value hours by 1h is above 23h set added TO hours to 23 and minutes to 59
            if (slotAddedToHours > 23) {
              slotAddedToHours = 23;
              slotAddedToMinutes = 59;
            }
            // if value of previous slot End value minutes is 59 set added FROM and TO hours to 23 and minutes to 59
            if (Number(toMinutes) === 59) {
              slotAddedToHours = 23;
              slotAddedToMinutes = 59;
              slotAddedFromHours = 23;
              slotAddedFromMinutes = 59;
            }
          }
        } else {
          // set first slot FROM and TO values
          slotAddedFromHours = 9;
          slotAddedFromMinutes = 0;
          slotAddedToHours = 10;
          slotAddedToMinutes = 0;
        }

        return {
          timeslotsIntervalsFrom: [
            ...props.availabilityData.timeslotsIntervalType[day]["from"],
            {
              value: getFormattedTimeValue(slotAddedFromHours, slotAddedFromMinutes),
              label: getFormattedTimeValue(slotAddedFromHours, slotAddedFromMinutes)
            }
          ],
          timeslotsIntervalsTo: [
            ...props.availabilityData.timeslotsIntervalType[day]["to"],
            {
              value: getFormattedTimeValue(slotAddedToHours, slotAddedToMinutes),

              label: getFormattedTimeValue(slotAddedToHours, slotAddedToMinutes)
            }
          ]
        };
      case "onePerAllDay":
        return !props.availabilityData.timeslotsPerDayType?.[day]?.["toggle"];
      default:
        return;
    }
  };

  const addTimeslot = (timeslotIndex, day) => {
    switch (props.availabilityData.event_type.type) {
      case "fixed":
        if (!addTimeslotDisableStatusPerDay[day].isDisabled) {
          props.updateStepAction(availabilityStep, {
            ...props.availabilityData,
            timeslotsFixedType: { ...props.availabilityData.timeslotsFixedType, [day]: getTimeslots(day) }
          });
        }
        props.setErrorFieldCheckFixedTimeslotsAction(
          day,
          timeslotIndex,
          "add",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );
        return;
      case "timeslotIntervals":
        if (!addTimeslotDisableStatusPerDay[day].isDisabled) {
          props.updateStepAction(availabilityStep, {
            ...props.availabilityData,
            timeslotsIntervalType: {
              ...props.availabilityData.timeslotsIntervalType,
              [day]: { from: getTimeslots(day)?.timeslotsIntervalsFrom, to: getTimeslots(day)?.timeslotsIntervalsTo }
            }
          });
        }
        props.setErrorFieldCheckIntervalsTimeslotsAction(
          day,
          timeslotIndex,
          "add",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );

        return;
      case "onePerAllDay":
        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsPerDayType: {
            ...props.availabilityData.timeslotsPerDayType,
            [day]: { ...props.availabilityData.timeslotsPerDayType[day], toggle: getTimeslots(day) }
          }
        });
        return;
      default:
        return;
    }
  };

  const removeTimeslot = (timeslotIndex, day) => {
    switch (props.availabilityData.event_type.type) {
      case "fixed":
        const filteredTimeslotForFixedType = props.availabilityData.timeslotsFixedType[day].filter(
          (f, index) => index !== timeslotIndex
        );

        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsFixedType: { ...props.availabilityData.timeslotsFixedType, [day]: filteredTimeslotForFixedType }
        });
        props.setErrorFieldCheckFixedTimeslotsAction(
          day,
          timeslotIndex,
          "remove",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );
        return;
      case "timeslotIntervals":
        const filteredTimeslotFrom = props.availabilityData.timeslotsIntervalType[day]["from"].filter(
          (f, index) => index !== timeslotIndex
        );
        const filteredTimeslotTo = props.availabilityData.timeslotsIntervalType[day]["to"].filter(
          (f, index) => index !== timeslotIndex
        );

        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsIntervalType: {
            ...props.availabilityData.timeslotsIntervalType,
            [day]: { from: filteredTimeslotFrom, to: filteredTimeslotTo }
          }
        });
        props.setErrorFieldCheckIntervalsTimeslotsAction(
          day,
          timeslotIndex,
          "remove",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );
        return;
      case "onePerAllDay":
      // delete if it's not needed
      default:
        return;
    }
  };

  const handleChangeDropdownTimeslot = (item, day, index, range) => {
    switch (props.availabilityData.event_type.type) {
      case "fixed":
        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsFixedType: {
            ...props.availabilityData.timeslotsFixedType,
            [day]: props.availabilityData.timeslotsFixedType[day].map((slot, i) => (index === i ? item : slot))
          }
        });
        props.setErrorFieldCheckFixedTimeslotsAction(
          day,
          index,
          "onChange",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );
        return;
      case "timeslotIntervals":
        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsIntervalType: {
            ...props.availabilityData.timeslotsIntervalType,
            [day]: {
              from:
                range === "from"
                  ? props.availabilityData.timeslotsIntervalType[day].from.map((slot, i) => (index === i ? item : slot))
                  : props.availabilityData.timeslotsIntervalType[day].from,
              to:
                range === "to"
                  ? props.availabilityData.timeslotsIntervalType[day].to.map((slot, i) => (index === i ? item : slot))
                  : props.availabilityData.timeslotsIntervalType[day].to
            }
          }
        });
        props.setErrorFieldCheckIntervalsTimeslotsAction(
          day,
          index,
          "onChange",
          status => {
            setAddTimeslotDisableStatusPerDay({ ...addTimeslotDisableStatusPerDay, [day]: { isDisabled: status } });
          },
          addTimeslotDisableStatusPerDay[day].isDisabled
        );
        return;
      case "onePerAllDay":
        props.updateStepAction(availabilityStep, {
          ...props.availabilityData,
          timeslotsPerDayType: {
            ...props.availabilityData.timeslotsPerDayType,
            [day]: {
              inputValues: [item],
              toggle: true
            }
          }
        });
        return;
      default:
        return;
    }
  };

  const handleCloseAlertDialog = () => {
    setIsAlertDialogOpen(false);
  };
  const handleTimeslotsDropdownConfirmation = () => {
    setIsAlertDialogOpen(false);
    updateAvailabilityStep(timeslotDropdownItemDialog);
  };

  return (
    <div className="row">
      <CustomAlertDialog
        isOpen={isAlertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleBtnLeft={handleCloseAlertDialog}
        handleBtnRight={handleTimeslotsDropdownConfirmation}
        title={"ARE YOU SURE?"}
        content={changeAvailabilityTimeslotTypeMsg}
        btnLeftLabel={"GO BACK"}
        btnRightLabel={"CHANGE ANYWAY"}
      />
      <div className="col-lg-6 left-side">
        <CustomFormDropdown
          alignRow={true}
          label="Timeslots"
          selectedItem={props.availabilityData.event_type}
          onChange={item => handleTimeslotsDropdown(item)}
          errorField={props.errorField}
          placeholder=""
          optionsList={timeslotsOptions}
          isRequired={showTimeslots}
          showTooltip={showTimeslots}
          isDisabled={!showTimeslots}
          showStar={true}
          tooltipTitle={tooltip.timeslots}
        />
        <CustomFormDropdown
          alignRow={true}
          label="Booking deadline"
          selectedItem={props.availabilityData.booking_deadline_in_hours}
          onChange={item =>
            props.updateStepAction(availabilityStep, { ...props.availabilityData, booking_deadline_in_hours: item })
          }
          errorField={props.errorField}
          placeholder=""
          optionsList={bookingDeadlineOptions}
          isRequired={true}
          isDisabled={false}
          showStar={true}
          tooltipTitle={tooltip.bookingDeadline}
        />
        <CustomFormDropdown
          alignRow={true}
          id="minimum_units"
          label="Minimum amount"
          selectedItem={props.availabilityData.minimum_units}
          onChange={item =>
            props.updateStepAction(availabilityStep, { ...props.availabilityData, minimum_units: item })
          }
          errorField={props.errorField}
          placeholder=""
          optionsList={minMaxAmountOptions}
          isRequired={true}
          isDisabled={false}
          showStar={true}
          tooltipTitle={tooltip.minAmount}
        />
        <CustomFormDropdown
          alignRow={true}
          id="maximum_units"
          label="Maximum amount"
          selectedItem={props.availabilityData.maximum_units}
          onChange={item =>
            props.updateStepAction(availabilityStep, { ...props.availabilityData, maximum_units: item })
          }
          errorField={props.errorField}
          placeholder=""
          optionsList={minMaxAmountOptions}
          isRequired={true}
          isDisabled={false}
          showStar={true}
          tooltipTitle={tooltip.maxAmount}
        />
      </div>
      <div className="col-lg-6 right-side right-side--availability">
        {/* TIMESLOTS */}
        {showTimeslots ? (
          days?.length > 0 &&
          days.map((day, index) => {
            return (
              <div key={index} className="form-group row">
                <label className="col-sm-2 col-form-label timeslot-day">{day}</label>
                <div className="col-sm-8">
                  <div className="timeslots-flex">
                    <AvailabilityTimeslotsPerType
                      addTimeslotDisableStatusPerDay={addTimeslotDisableStatusPerDay}
                      availabilityErrorFixed={props.availabilityErrorFixed}
                      availabilityErrorMsg={props.availabilityErrorMsg}
                      availabilityErrorIntervals={props.availabilityErrorIntervals}
                      onChangeDropdownTimeslot={handleChangeDropdownTimeslot}
                      removeTimeslot={removeTimeslot}
                      addTimeslot={addTimeslot}
                      inputs={renderDay(day)}
                      chosenInputType={props.availabilityData.event_type.type}
                      chosenInputIncrement={props.availabilityData.event_type.increment}
                      day={day}
                      index={index}
                      getOptionsForDropdowns={props.getOptionsForDropdowns(props.availabilityData.event_type.increment)}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          // Show message instead of Timeslots
          <div className="timeslots-message-wrapper">
            <p className="timeslots-message">
              {timeslotsCustomSetupMessage1} <br></br>
              <br></br>
              {timeslotsCustomSetupMessage2}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  availabilityErrorFixed: state.offers.availabilityErrorFixed,
  availabilityErrorMsg: state.offers.availabilityErrorMsg,
  availabilityErrorIntervals: state.offers.availabilityErrorIntervals,
  availabilityData: state.offers.availabilityData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setErrorFieldCheckFixedTimeslotsAction: offersActionCreators.setErrorFieldCheckFixedTimeslotsAction,
      setErrorFieldCheckIntervalsTimeslotsAction: offersActionCreators.setErrorFieldCheckIntervalsTimeslotsAction,
      updateStepAction: offersActionCreators.updateStepAction,
      resetAvailabilityTimeslotsDataAction: offersActionCreators.resetAvailabilityTimeslotsDataAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityStep);
