import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
// components
// @ts-ignore
import { CustomLoader } from "@gl-shared/shared/ui";
import AppHeader from "../../AppHeader";
import AppSidebar from "../../AppSidebar";
// helpers
import {
  delayForHidingFormMsg,
  pagesUrl,
  providerRootUrl,
  removeTrailingSlash,
  getCookie,
  isTokenExpired
} from "../../../common/utils/panel-helpers/helpers";
// actions
import { useActions } from "../../../common/hooks/useActions";
import { homeActionCreators } from "../../../common/store/modules/homeModule";
import { bookingsActionCreators } from "../../../common/store/modules/bookingsModule";
import { dashboardActionCreators } from "../../../common/store/modules/dashboardModule";
import { settingsActionCreators } from "../../../common/store/modules/settingsModule";
import { globalActionCreators } from "../../../common/store/modules/globalModule";
import { loginsActionCreators } from "../../../common/store/modules/loginsModule";

// App Layout for authenticated routes

const startingDateRange = {
  startDate: moment().subtract(6, "days"),
  endDate: moment()
};

export default function AppLayoutAuthenticated() {
  const location = useLocation();
  const timerFormMessageRef = useRef(null);
  const navigate = useNavigate();

  // states
  const userToken = useSelector((state: any) => state.login.userToken);
  const isUserAuthenticated = useSelector((state: any) => state.login.isUserAuthenticated);
  const loading = useSelector((state: any) => state.global.loading);

  // actions
  const loadHotelInfo = useActions(homeActionCreators?.loadHotelInfoAction, []);
  const loadBookings = useActions(bookingsActionCreators?.loadBookingsAction, []);
  const loadDashboardData = useActions(dashboardActionCreators?.loadDashboardDataAction, []);
  const loadHotelSettings = useActions(settingsActionCreators?.loadHotelSettingsAction, []);
  const toggleSectionLoader = useActions(globalActionCreators?.toggleSectionLoaderAction, []);
  const setFormData = useActions(globalActionCreators?.setFormDataAction, []);
  const loadLoginsData = useActions(loginsActionCreators?.loadLoginsAction, []);

  /* Formatted URLS */
  // urls with removed "/" at the and --> in order to compare with window pathname
  // window pathname with removed "/" at the and
  const locationUrlWithoutSlash = removeTrailingSlash(location?.pathname);
  // root url (Dashboard page) and /bookings url (Bookings page) with removed "/" at the and
  const rootUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}`);
  const bookingsUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}/${pagesUrl.bookings}`);
  /* END OFF Formatted URLS */

  useEffect(() => {
    if (userToken && !isTokenExpired(getCookie(`${providerRootUrl}-expires`))) {
      initialCall();
    } else {
      if (isUserAuthenticated === false || isTokenExpired(getCookie(`${providerRootUrl}-expires`))) {
        navigate(`/${providerRootUrl}/${pagesUrl.login}`);
      }
    }
    return () => {
      // @ts-ignore
      clearTimeout(timerFormMessageRef.current);
    };
  }, [userToken, isUserAuthenticated]);

  const hideFormMessage = (delay: any) => {
    // @ts-ignore
    timerFormMessageRef.current = setTimeout(
      () =>
        setFormData({
          formSuccessful: false,
          formError: false
        }),
      delay ? delay : 0
    );
  };

  const handleFormMessage = (status: any, form: any) => {
    if (status === "ok") {
      setFormData({
        formSuccessful: true,
        form: form
      });
    } else {
      setFormData({
        formError: true,
        form: form
      });
    }

    // Hiding FormMessage for all cases, EXCEPT for Hotel Group Manager role
    // For that role hiding FormMessage is handled separately --> look handleSectionLoader method
    if (form !== "HotelSelectionNotice") {
      hideFormMessage(delayForHidingFormMsg);
    }
  };

  const handleSectionLoader = (loadingState: any, sectionName: any) => {
    toggleSectionLoader(loadingState, sectionName);

    // Hide FormMessage (with timeout) after loading is finished
    if (loadingState === false) {
      hideFormMessage(delayForHidingFormMsg);
    }
  };

  // Loading hotel settings
  const loadSettings = (hotelId: any) => loadHotelSettings(hotelId, handleSectionLoader);

  // Data for Logins component
  const loadLogins = () => loadLoginsData(handleSectionLoader);

  const loadDashboardAndThenBookings = () => {
    if (locationUrlWithoutSlash === rootUrlWithoutSlash) {
      loadDashboardData(startingDateRange, locationUrlWithoutSlash);
      loadBookings(startingDateRange, locationUrlWithoutSlash);
    }
  };

  const loadBookingsAndThenDashboard = () => {
    if (locationUrlWithoutSlash === bookingsUrlWithoutSlash) {
      loadBookings(startingDateRange, locationUrlWithoutSlash);
      loadDashboardData(startingDateRange, locationUrlWithoutSlash);
    }
  };

  const initialCall = () => {
    loadHotelInfo();

    // In case window pathname is equal to root url (Dashboard page) load Dashboard data first, Bookings data second and trigger Global Loader only while loading Dashboard data
    loadDashboardAndThenBookings();

    // In case window pathname is equal to /bookings url (Bookings page) load Bookings data first, Dashboard data second and trigger Global Loader only while loading Bookings data
    loadBookingsAndThenDashboard();

    // In case window pathname is other than Dashboard ("/") or Bookings url (Bookings page) load Dashboard data first, Bookings data second and trigger Global Loader only while loading Dashboard data
    if (locationUrlWithoutSlash !== rootUrlWithoutSlash && locationUrlWithoutSlash !== bookingsUrlWithoutSlash) {
      loadDashboardData(startingDateRange, rootUrlWithoutSlash);
      loadBookings(startingDateRange, rootUrlWithoutSlash);
    }

    loadSettings(undefined);
    loadLogins();
  };

  return (
    <div className="app-layout" data-cy="provider">
      <Box className="app-container">
        <div id="page-wrapper">
          {userToken && (
            <>
              <CustomLoader loading={loading} loaderCustomStyle="loader__container__panel" />
              <AppHeader
                loadLogins={loadLogins}
                loadSettings={loadSettings}
                handleSectionLoader={handleSectionLoader}
                loadDashboardAndThenBookings={loadDashboardAndThenBookings}
                loadBookingsAndThenDashboard={loadBookingsAndThenDashboard}
              />
              <AppSidebar />
            </>
          )}
          <div className="wrapper wrapper-content">
            <Outlet
              context={{
                loadLogins: loadLogins,
                loadSettings: loadSettings,
                handleFormMessage: handleFormMessage,
                handleSectionLoader: handleSectionLoader
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}
