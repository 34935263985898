// icons
// TODO: Move this to npm package (@gl-shared/shared/vector)
import { loginLogo } from "../../common/vector";

interface LoginOverlayProps {
  form: JSX.Element;
  header?: string;
  subtitle?: string;
  infoSection?: JSX.Element;
  backgroundImageClass: string;
}

export default function LoginOverlay({ backgroundImageClass, infoSection, header, subtitle, form }: LoginOverlayProps) {
  return (
    <div className={`login-page ${backgroundImageClass}`}>
      <div className="login-page__overlay">
        <div className="login">
          <div className="login__logo">
            <img src={loginLogo.default} alt="logo-icon" />
          </div>
          {infoSection ? (
            infoSection
          ) : (
            <>
              <div className="login__header">{header}</div>
              <div className="login__subtitle">{subtitle}</div>
            </>
          )}

          <div>
            <div className="login-form-box">{form}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
