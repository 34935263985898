import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
// components
import AddEditHeader from "./AddEditHeader";
import AddEditButtons from "./AddEditButtons";
import OffersStepLoading from "../steps/OffersStepLoading";
// steps
import OfferDetailsStep from "../steps/OfferDetailsStep";
import PricingStep from "../steps/PricingStep";
import AvailabilityStep from "../steps/AvailabilityStep";
import PhotosStep from "../steps/PhotosStep";
import FulfilmentStep from "../steps/FulfilmentStep";
import SummaryStep from "../steps/SummaryStep";
// helpers
import { getOptionsWithIncrementForDropdown, offersStep } from "../offersHelpers";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";

const AddEditOffer = props => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const isEdit = !window.location.pathname.includes("/new");

  const loadOffers = hotelId => props.loadOffersAction(hotelId, outletContext?.handleSectionLoader);

  const renderSelectedStep = () => {
    const hotelEventCategories = props?.hotelData?.hotelEventCategories;
    const hotelCurrency = props?.hotelData?.hotelCurrency;
    const hotelVat = Number(props?.hotelData?.hotelVatTaxRate).toFixed(2);

    switch (props.selectedStep) {
      case offersStep.offerDetails:
        return <OfferDetailsStep errorField={props.errorField} hotelEventCategories={hotelEventCategories} />;
      case offersStep.pricing:
        return (
          <PricingStep
            errorField={props.errorField}
            hotelCurrency={hotelCurrency}
            hotelVat={hotelVat}
            isEdit={isEdit}
          />
        );
      case offersStep.availability:
        return (
          <AvailabilityStep
            errorField={props.errorField}
            getOptionsForDropdowns={value => getOptionsWithIncrementForDropdown(value)}
          />
        );
      case offersStep.photos:
        return <PhotosStep errorField={props.errorField} />;
      case offersStep.fulfilment:
        return <FulfilmentStep errorField={props.errorField} />;
      case offersStep.summary:
        return <SummaryStep errorField={props.errorField} hotelCurrency={hotelCurrency} />;
      default:
        return null;
    }
  };

  const handleSaveOfferButton = () => {
    const hotelCurrency = props?.hotelData?.hotelCurrency;
    props.saveOfferAction(outletContext.handleFormMessage, loadOffers, hotelCurrency, isEdit, navigate);
  };

  const handleDeleteOfferButton = () => {
    props.deleteOfferAction(outletContext.handleFormMessage, loadOffers, props.offerDetailsData.event_code, navigate);
  };

  return (
    <div className="hotel-offers component">
      <div className="hotel-offers__box">
        <div className="ibox ">
          <div className="ibox-title">
            <AddEditHeader
              validatedSteps={props.validatedSteps}
              setSelectedStepAction={props.setSelectedStepAction}
              selectedStep={props.selectedStep}
              isEdit={isEdit}
              offer={{ name: props.offerDetailsData.name }}
              offerStepLoader={props.offerStepLoader}
            />
          </div>
          <div className="ibox-content hotel-offers__box__add-edit">
            {/* Loader for saving the offer */}
            {props.offerStepLoader && <OffersStepLoading message={"Saving your offer" + "\n" + "please wait"} />}
            <div className={`hotel-offers__step ${props.offerStepLoader && "visibility-hidden"}`}>
              {renderSelectedStep()}
            </div>
            {/* Do not show Delete/Cancel/Save button while loading is active */}
            {!props.offerStepLoader && (
              <AddEditButtons
                errorExists={props.errorExists}
                isEdit={isEdit}
                selectedStep={props.selectedStep}
                setSelectedStepAction={props.setSelectedStepAction}
                onSave={handleSaveOfferButton}
                onDelete={handleDeleteOfferButton}
                isFormChanged={props.isFormChanged}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  offerStepLoader: state.offers.offerStepLoader,
  selectedStep: state.offers.selectedStep,
  hotelData: state.home.hotelData,
  offerDetailsData: state.offers.offerDetailsData,
  errorField: state.errorField.errorField,
  errorExists: state.errorField.errorExists,
  isFormChanged: state.offers.isFormChanged,
  validatedSteps: state.offers.validatedSteps
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setSelectedStepAction: offersActionCreators.setSelectedStepAction,
      saveOfferAction: offersActionCreators.saveOfferAction,
      deleteOfferAction: offersActionCreators.deleteOfferAction,
      loadOffersAction: offersActionCreators.loadOffersAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditOffer);
