// components
import LoginOverlay from "../../components/LoginOverlay";
import LoginForm from "./LoginForm";

export default function Login() {
  return (
    <LoginOverlay
      backgroundImageClass="provider-login-page"
      header="Login"
      subtitle="Provider Panel"
      form={<LoginForm />}
    />
  );
}
