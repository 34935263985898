import { useState } from "react";
// helpers
// @ts-ignore
import { checkForValidEmail } from "@gl-shared/shared/helpers";

interface ResetMyPasswordProps {
  onResetMyPassword: (email: string) => void;
  apiError: boolean;
}

const ResetMyPassword = ({ onResetMyPassword, apiError }: ResetMyPasswordProps) => {
  const [email, setEmail] = useState("");
  const [emailInputError, setEmailInputError] = useState(false);

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleResetMyPassword = async () => {
    if (email?.length === 0 || checkForValidEmail(email)) {
      setEmailInputError(true);
    } else {
      onResetMyPassword(email);
    }
  };

  const handleKeypress = (event: any) => {
    if (event.key === "Enter") {
      handleResetMyPassword();
    }
  };

  return (
    <div className="screen-wrapper">
      {apiError && (
        <div className="text-instructions--warning">
          Something went wrong, please try again later.
          <br /> <br />
        </div>
      )}
      <div className="text-instructions">
        Forgot your password? Don’t worry, it happens to us all. Please enter your e-mail address below.
      </div>
      <div className="reset-password-email-input-wrapper">
        <input
          type="text"
          className={`login__input ${emailInputError && "input-error"}`}
          placeholder="E-Mail"
          onChange={handleChangeEmail}
          value={email}
          onKeyPress={handleKeypress}
        />
      </div>
      <button className="reset-password-submit-button" type="submit" onClick={handleResetMyPassword}>
        RESET MY PASSWORD
      </button>
    </div>
  );
};

export default ResetMyPassword;
