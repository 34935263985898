import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// @ts-ignore
import { ThemeProvider } from "@mui/material/styles";
// store
import { store } from "./common/store";
// INSPINIA - Responsive Admin Theme
import "./common/styles/inspinia-theme/css/style.css";
// styles
import "./common/styles/styles.scss";
import "@gl-shared/shared/main.css";
import reportWebVitals from "./reportWebVitals";
// App Router
import AppRouter from "./common/routes";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// @ts-ignore
import { theme } from "./common/styles/theme.d.ts";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    {/*  TODO: Check and turn on StrictMode in near future, for local development */}
    {/*<React.StrictMode>*/}
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
    {/*</React.StrictMode>*/}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
