import { connect } from "react-redux";
// icons
import { infoCircleLightGrayIcon } from "@gl-shared/shared/vector";
// helpers
import { getSummaryBoxContainerItems } from "../offersHelpers";

const SummaryStep = props => {
  // Summary box items data
  const detailsAndPricingItems = getSummaryBoxContainerItems("detailsAndPricing", props);
  const availabilityItems = getSummaryBoxContainerItems("availability", props);
  const timeslotItems = getSummaryBoxContainerItems("timeslots", props);

  return (
    <div className="row summary">
      <div className="col-lg-12">
        <div className="row summary__box-container">
          <div className="summary__box-container__column4">
            <div className="summary__demi-bold">Offer Details & Pricing</div>
            <div className="summary__box-container__item">
              {detailsAndPricingItems?.length > 0 &&
                detailsAndPricingItems?.map(item => (
                  <div key={item.label} className="row row-padding">
                    <div className="col-lg-6 summary__demi-bold">{item.label}</div>
                    <div className="col-lg-6 summary__box-container__item__value-text">{item.value}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="summary__box-container__column4">
            <div className="summary__demi-bold">Availability Settings</div>
            <div className="summary__box-container__item">
              {availabilityItems?.length > 0 &&
                availabilityItems?.map(item => (
                  <div key={item.label} className="row row-padding">
                    <div className="col-lg-6 summary__demi-bold">{item.label}</div>
                    <div className="col-lg-6 summary__box-container__item__value-text">{item.value}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="summary__box-container__column4">
            <div className="summary__demi-bold">Timeslots</div>
            <div className="summary__box-container__item">
              {timeslotItems?.length > 0 &&
                timeslotItems?.map(item => (
                  <div key={item.label} className="row row-padding">
                    <div className="col-lg-6 summary__demi-bold">{item.label}</div>
                    <div className="col-lg-6 summary__box-container__item__value-text">{item.value}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="row summary__box-container">
          <div className="col-lg-12">
            <div className="summary__demi-bold">Photos</div>
            <div className="summary__box-container__item summary__box-container__item--photos-box">
              <div className="custom-dropzone__images">
                {props.photosData?.length > 0
                  ? props.photosData.map((file, index) => {
                      return (
                        <div key={file.path} className="custom-dropzone__images__column">
                          <img src={file?.preview} alt={file?.name} />
                          <div className="custom-dropzone__images__column__main-img-info">
                            {index === 0 && (
                              <div className="custom-dropzone__images__column__main-img-info__container">
                                <div className="custom-dropzone__images__column__main-img-info__img-wrapper">
                                  <img src={infoCircleLightGrayIcon} alt="info" />
                                </div>
                                This is your main image
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : "no photos uploaded"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  offerDetailsData: state.offers.offerDetailsData,
  pricingData: state.offers.pricingData,
  availabilityData: state.offers.availabilityData,
  photosData: state.offers.photosData,
  fulfilmentData: state.offers.fulfilmentData
});

export default connect(mapStateToProps, null)(SummaryStep);
