import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// components
import StoreNewPassword from "../../../components/ForgotPassword/screens/StoreNewPassword";
// actions
import { useActions } from "../../../common/hooks/useActions";
import { resetPasswordActionCreators } from "../resetPasswordModule";

export default function StoreNewPasswordPage() {
  const navigate = useNavigate();

  // actions
  const saveResetPassword = useActions(resetPasswordActionCreators?.saveResetPasswordAction, []);

  // states
  const savePasswordApiError = useSelector((state: any) => state.resetPassword.savePasswordApiError);

  return (
    <StoreNewPassword
      saveResetPassword={saveResetPassword}
      savePasswordApiError={savePasswordApiError}
      navigate={navigate}
      backgroundImageClass="provider-login-page"
    />
  );
}
