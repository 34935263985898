import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// helpers
import { providerRootUrl, pagesUrl } from "../../utils/panel-helpers/helpers";
// actions
import { useActions } from "../../hooks/useActions";
import { resetPasswordActionCreators } from "../../../pages/ResetPassword/resetPasswordModule";

const PrivateStoreNewPasswordRoute = ({ children }: any) => {
  // actions
  const checkIsTokenValid = useActions(resetPasswordActionCreators?.checkIsTokenValidAction, []);

  // states
  const isTokenValid = useSelector((state: any) => state.resetPassword.isTokenValid);

  useEffect(() => {
    checkIsTokenValid();
  }, []);

  if (isTokenValid) {
    return children;
  } else {
    return <Navigate to={`/${providerRootUrl}/${pagesUrl.invalidToken}`} />;
  }
};

export default PrivateStoreNewPasswordRoute;
