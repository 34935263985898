import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// components
import { CustomDropzone } from "@gl-shared/shared/ui";
// helpers
import { createOfferDropzoneOptions, offersStep } from "../offersHelpers";
import { getStringFromListOfValidFormats } from "@gl-shared/shared/helpers";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";

const photosStep = offersStep.photos;

const PhotosStep = props => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <CustomDropzone
          maxFiles={createOfferDropzoneOptions.maxFiles}
          maxSize={createOfferDropzoneOptions.maxSize}
          acceptedFiles={createOfferDropzoneOptions.acceptedFiles}
          files={props.photosData}
          setFiles={files => props.updateStepAction(photosStep, files)}
          dropzoneLabel={`Drag and drop \n your images here or click to upload`}
          dropzoneAdditionalLabel={`supported image types: ${getStringFromListOfValidFormats(
            createOfferDropzoneOptions.acceptedFiles
          ).toUpperCase()}`}
          enableImagePreview
          onRemoveFile={file => props.removePhotoIdAction(file)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  photosData: state.offers.photosData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateStepAction: offersActionCreators.updateStepAction,
      removePhotoIdAction: offersActionCreators.removePhotoIdAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosStep);
