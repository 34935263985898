// components
import { CustomTable } from "@gl-shared/shared/ui";

const OffersList = props => {
  return (
    <div className="ibox-content">
      {props?.panelOffers?.length > 0 ? (
        <CustomTable
          showEmptyTableMessage={false}
          customClasses="panel__table"
          customId={"event_code"}
          tableHead={[
            { title: "Name", column: "name" },
            { title: "Offer Code", column: "event_code" },
            {
              title: "Base Price",
              column: "base_price",
              edited: {
                func: item => {
                  return `${props.hotelCurrency} ${item.hotel_pricing?.base_price?.toFixed(2)}`;
                }
              }
            }
          ]}
          tableItems={props?.panelOffers}
          tableButtonClick={item => props.onEditButton(item)}
        />
      ) : (
        <div className="hotel-offers__message hotel-offers__message--bottom">You have not added any offers yet.</div>
      )}
    </div>
  );
};

export default OffersList;
