import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// components
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomFormTextArea } from "@gl-shared/shared/ui";
import { CustomFormSwitchToggle } from "@gl-shared/shared/ui";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";
// helpers
import { offersStep, tooltip } from "../offersHelpers";

const fulfilmentStep = offersStep.fulfilment;

const FulfilmentStep = props => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <CustomFormSwitchToggle
          tooltipTitle={tooltip.instantConfirmation}
          label="Instant confirmation?"
          checked={props.fulfilmentData.directly_bookable}
          onChange={() =>
            props.updateStepAction(fulfilmentStep, {
              ...props.fulfilmentData,
              directly_bookable: !props.fulfilmentData.directly_bookable
            })
          }
        />
        <CustomFormInput
          type="email"
          name="booking_confirmation_copy_recipients"
          id="booking_confirmation_copy_recipients"
          label="Send copy of booking confirmation to"
          tooltipTitle={tooltip.sendCopyConfirmationTo}
          value={props.fulfilmentData.booking_confirmation_copy_recipients}
          onInputChange={event =>
            props.updateStepAction(fulfilmentStep, {
              ...props.fulfilmentData,
              booking_confirmation_copy_recipients: event.target.value
            })
          }
          errorField={props.errorField}
          alignRow={true}
        />
        {!props.fulfilmentData.directly_bookable && (
          <>
            <CustomFormInput
              type="email"
              name="bookingRequestTo"
              id="bookingRequestTo"
              label="Send booking request to"
              tooltipTitle={tooltip.sendBookingRequestTo}
              value={props.fulfilmentData.bookingRequestTo}
              onInputChange={event =>
                props.updateStepAction(fulfilmentStep, {
                  ...props.fulfilmentData,
                  bookingRequestTo: event.target.value
                })
              }
              errorField={props.errorField}
              isRequired={true}
              alignRow={true}
            />
            <CustomFormTextArea
              name="fulfilment-instructions"
              label="Fulfilment instructions for your team"
              tooltipTitle={tooltip.fulfilmentInstructions}
              value={props.fulfilmentData.service_provider_instructions}
              onChange={event =>
                props.updateStepAction(fulfilmentStep, {
                  ...props.fulfilmentData,
                  service_provider_instructions: event.target.value
                })
              }
              errorField={null}
              bigTextarea={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  fulfilmentData: state.offers.fulfilmentData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateStepAction: offersActionCreators.updateStepAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FulfilmentStep);
