interface BackToLoginProps {
  onBackToLoginClick: () => void;
}

const BackToLogin = ({ onBackToLoginClick }: BackToLoginProps) => {
  return (
    <div className="screen-wrapper">
      <div className="text-instructions">
        Thank you! If an account with your e-mail address exists, we will send you a message with further instructions
        momentarily.
        <br /> <br />
        If you don’t receive any e-mail from us, please also check your spam folder.
      </div>
      <div className="separator-div" />
      <div onClick={onBackToLoginClick} className="reset-password-submit-button">
        BACK TO LOGIN
      </div>
    </div>
  );
};

export default BackToLogin;
