// icons
// @ts-ignore
import { loadingDotsDarkGreyIcon } from "@gl-shared/shared/vector";

const SectionLoader = (props: any) => {
  return (
    <div className="section__loader">
      <img src={loadingDotsDarkGreyIcon} alt="loading..." />
      <div className="section__loader-text">{props?.loadingLabel}</div>
    </div>
  );
};

export default SectionLoader;
