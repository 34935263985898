import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
// helpers
import { pagesUrl, appSection, providerRootUrl } from "../../common/utils/panel-helpers/helpers";
// components
import FormMessage from "../../components/FormMessage";
import SectionLoader from "../../components/SectionLoader";
// @ts-ignore
import { CustomButton } from "@gl-shared/shared/ui";
// @ts-ignore
import { CustomTable } from "@gl-shared/shared/ui";
// actions
import { loginsActionCreators } from "../../common/store/modules/loginsModule";

const tableHeadRows = [
  { title: "Name", column: "full_name" },
  { title: "Email", column: "email" }
];

const Logins = (props: any) => {
  const navigate = useNavigate();

  const addLoginForm = () => {
    window.scrollTo(0, 0);
    navigate(`/${providerRootUrl}/${pagesUrl.addLogins}`);
  };

  const editLoginForm = (selectedLogin: any) => {
    window.scrollTo(0, 0);
    props.setSelectedLoginAction(selectedLogin);
    navigate(`/${providerRootUrl}/${pagesUrl.editLogins}`);
  };

  return (
    <div className="logins component">
      {props.sectionLoadingNames?.includes(appSection?.logins) ? (
        <SectionLoader loadingLabel="LOADING LOGINS" />
      ) : (
        <>
          {props.formData?.form === "AddLogin" ||
          props.formData?.form === "EditLogin" ||
          props.formData?.form === "DeleteLogin" ? (
            <FormMessage
              form={props.formData?.form}
              formError={props.formData?.formError}
              formSuccessful={props.formData?.formSuccessful}
            />
          ) : null}
          {props.formData?.form === "HotelSelectionNotice" && (
            <FormMessage form={props.formData?.form} hotelName={props.formData?.hotelName} />
          )}
          <div className="row">
            <div className="col-lg-8">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Logins</h5>
                  <CustomButton
                    isDisabled={false}
                    mainClass="btn-green"
                    customClasses="btn-green--height-l btn-green--auto-width"
                    onClick={addLoginForm}
                    buttonText="ADD LOGIN"
                  />
                </div>
                <div className="ibox-content">
                  <CustomTable
                    emptyTableMessage={"No logins created yet"}
                    customClasses="panel__table logins__table"
                    tableHead={tableHeadRows}
                    tableItems={props?.logins}
                    tableButtonClick={(item: any) => editLoginForm(item)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  formData: state.global.formData,
  sectionLoadingNames: state.global.sectionLoadingNames,
  logins: state.logins.logins
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setSelectedLoginAction: loginsActionCreators.setSelectedLoginAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Logins);
