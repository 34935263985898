import ApiHelper from "../../common/utils/apiHelper";
// helpers
import { pagesUrl, providerRootUrl } from "../../common/utils/panel-helpers/helpers";

export const resetPasswordActionTypes = {
  SET_EMAIL_API_ERROR: "SET_EMAIL_API_ERROR",
  SET_SAVE_PASSWORD_API_ERROR: "SET_SAVE_PASSWORD_API_ERROR",
  SET_TOKEN_VALID_STATE: "SET_TOKEN_VALID_STATE"
};

export const initialState = {
  sendEmailApiError: false,
  savePasswordApiError: false,
  isTokenValid: true
};

const resetPasswordModule = (state: any = initialState, action: any) => {
  switch (action.type) {
    case resetPasswordActionTypes.SET_EMAIL_API_ERROR:
      return {
        ...state,
        sendEmailApiError: action.sendEmailApiError
      };
    case resetPasswordActionTypes.SET_SAVE_PASSWORD_API_ERROR:
      return {
        ...state,
        savePasswordApiError: action.savePasswordApiError
      };
    case resetPasswordActionTypes.SET_TOKEN_VALID_STATE:
      return {
        ...state,
        isTokenValid: action.isTokenValid
      };

    default:
      return state;
  }
};

export default resetPasswordModule;

export const resetPasswordActionCreators = {
  sendEmailForResetPasswordAction:
    (email: string, onResetMyPassword: (activeScreen: string) => void) => async (dispatch: any) => {
      try {
        await ApiHelper.post(
          `vX/reset-password`,
          {},
          JSON.stringify({
            email: email
          })
        );
        onResetMyPassword("backToLogin");
        // reset sendEmailApiError to default value
        dispatch({
          type: resetPasswordActionTypes.SET_EMAIL_API_ERROR,
          sendEmailApiError: false
        });
      } catch (error) {
        dispatch({
          type: resetPasswordActionTypes.SET_EMAIL_API_ERROR,
          sendEmailApiError: true
        });
      }
    },
  checkIsTokenValidAction: () => async (dispatch: any) => {
    /*
    User gets Email with URL for changing the password, something like this: "https://dev.get-local.com/provider/reset-password/store-new-password/${token}"
    On opening this link we should call the GET EP and check if token still valid
    * */
    try {
      const res = await ApiHelper.get(`vX/${window.location.pathname.split(`/${providerRootUrl}/`).pop()}` || "");

      if (res?.data === "success") {
        dispatch({
          type: resetPasswordActionTypes.SET_TOKEN_VALID_STATE,
          isTokenValid: true
        });
      }
    } catch (error) {
      dispatch({
        type: resetPasswordActionTypes.SET_TOKEN_VALID_STATE,
        isTokenValid: false
      });
    }
  },
  saveResetPasswordAction: (newPassword: string, repeatPassword: string, navigate: any) => async (dispatch: any) => {
    let bodyFormData = new FormData();

    bodyFormData.append("password", newPassword);
    bodyFormData.append("repeatedPassword", repeatPassword);

    const getTokenFromUrl: string = window.location.pathname.split("store-new-password/").pop() || "";

    try {
      await ApiHelper.post(`vX/reset-password/store-new-password/${getTokenFromUrl}`, {}, bodyFormData, {
        "Content-Type": "multipart/form-data"
      });

      navigate(`/${providerRootUrl}/${pagesUrl.login}`, {
        state: {
          passwordResetSuccessfully: true
        }
      });
      // reset sendEmailApiError to default value
      dispatch({
        type: resetPasswordActionTypes.SET_SAVE_PASSWORD_API_ERROR,
        savePasswordApiError: false
      });
    } catch (error) {
      dispatch({
        type: resetPasswordActionTypes.SET_SAVE_PASSWORD_API_ERROR,
        savePasswordApiError: true
      });
    }
  }
};
