import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";
// helpers
import { hotelPriceTypeOptions, offersStep, tooltip } from "../offersHelpers";
// components
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomFormDropdown } from "@gl-shared/shared/ui";
import { CustomFormSwitchToggle } from "@gl-shared/shared/ui";

const pricingStep = offersStep.pricing;

const PricingStep = props => {
  useEffect(() => {
    if (!props.isEdit && !props.pricingData?.vatEdited) {
      props.updateStepAction(pricingStep, { ...props.pricingData, vat: props.hotelVat, vatEdited: true });
    }
  }, []);

  const isSelectedPriceTypePerPerson = () => props.pricingData.price_type?.value === "per_person";

  return (
    <div className="row">
      <div className="col-lg-6 left-side">
        <CustomFormInput
          isDisabled={true}
          name="currency"
          id="currency"
          label="Currency"
          tooltipTitle={tooltip.currency}
          value={props.hotelCurrency}
          isRequired={true}
          alignRow={true}
        />
        <CustomFormInput
          isDisabled={props.isEdit}
          type="number"
          min={0}
          name="vat"
          id="vat"
          label="VAT rate in %"
          tooltipTitle={tooltip.vat}
          value={props.pricingData?.vat && props.pricingData?.vat}
          onInputBlur={value => {
            if (!props.isEdit) {
              props.updateStepAction(pricingStep, { ...props.pricingData, vat: value });
            }
          }}
          errorField={props.errorField}
          isRequired={true}
          alignRow={true}
          maskedNumberInput={true}
        />
        <CustomFormDropdown
          alignRow={true}
          label="Price type"
          id="price_type"
          selectedItem={props.pricingData.price_type}
          onChange={item => props.updateStepAction(pricingStep, { ...props.pricingData, price_type: item })}
          errorField={props.errorField}
          placeholder=""
          optionsList={hotelPriceTypeOptions}
          isRequired={true}
          isDisabled={false}
          tooltipTitle={tooltip.priceType}
        />
        <CustomFormInput
          type="number"
          min={0}
          step={1}
          name="base_price"
          id="base_price"
          label="Base price"
          tooltipTitle={tooltip.basePrice}
          value={props.pricingData?.hotel_pricing?.base_price && props.pricingData?.hotel_pricing?.base_price}
          onInputBlur={value =>
            props.updateStepAction(pricingStep, {
              ...props.pricingData,
              hotel_pricing: {
                ...props.pricingData?.hotel_pricing,
                base_price: value
              }
            })
          }
          errorField={props.errorField}
          isRequired={true}
          alignRow={true}
          maskedNumberInput={true}
        />
        <CustomFormInput
          type="number"
          min={0}
          step={1}
          name="additional_unit_price"
          id="additional_unit_price"
          label="Additional unit price"
          tooltipTitle={tooltip.additionalUnitPrice}
          value={
            props.pricingData?.hotel_pricing?.additional_unit_price &&
            props.pricingData?.hotel_pricing?.additional_unit_price
          }
          onInputBlur={value =>
            props.updateStepAction(pricingStep, {
              ...props.pricingData,
              hotel_pricing: {
                ...props.pricingData?.hotel_pricing,
                additional_unit_price: value
              }
            })
          }
          errorField={props.errorField}
          isRequired={true}
          alignRow={true}
          maskedNumberInput={true}
        />
        <CustomFormInput
          maxLength="15"
          type="text"
          name="base_price_description"
          id="base_price_description"
          label="Price description"
          tooltipTitle={tooltip.priceDescription}
          value={props.pricingData?.hotel_pricing?.base_price_description || ""}
          onInputChange={event =>
            props.updateStepAction(pricingStep, {
              ...props.pricingData,
              hotel_pricing: {
                ...props.pricingData?.hotel_pricing,
                base_price_description: event.target.value
              }
            })
          }
          errorField={null}
          alignRow={true}
        />
      </div>
      <div className="col-lg-6 right-side">
        {isSelectedPriceTypePerPerson() && (
          <>
            <CustomFormSwitchToggle
              tooltipTitle={tooltip.allowChildren}
              label="Allow children"
              checked={props.pricingData?.hotel_pricing?.allow_children}
              onChange={() =>
                props.updateStepAction(pricingStep, {
                  ...props.pricingData,
                  hotel_pricing: {
                    ...props.pricingData?.hotel_pricing,
                    allow_children: !props.pricingData?.hotel_pricing?.allow_children
                  }
                })
              }
            />
            {props.pricingData?.hotel_pricing?.allow_children && (
              <>
                <CustomFormInput
                  type="number"
                  min={0}
                  step={1}
                  name="child_price"
                  id="child_price"
                  label="Price per child"
                  tooltipTitle={tooltip.pricePerChild}
                  value={props.pricingData?.hotel_pricing?.child_price && props.pricingData?.hotel_pricing?.child_price}
                  onInputBlur={value =>
                    props.updateStepAction(pricingStep, {
                      ...props.pricingData,
                      hotel_pricing: {
                        ...props.pricingData?.hotel_pricing,
                        child_price: value
                      }
                    })
                  }
                  errorField={props.errorField}
                  isRequired={true}
                  alignRow={true}
                  maskedNumberInput={true}
                />
                <CustomFormInput
                  maxLength="15"
                  type="text"
                  name="child_price_description"
                  id="child_price_description"
                  label="Child price description"
                  tooltipTitle={tooltip.childPriceDescription}
                  value={props.pricingData?.hotel_pricing?.child_price_description || ""}
                  onInputChange={event =>
                    props.updateStepAction(pricingStep, {
                      ...props.pricingData,
                      hotel_pricing: {
                        ...props.pricingData?.hotel_pricing,
                        child_price_description: event.target.value
                      }
                    })
                  }
                  errorField={null}
                  alignRow={true}
                />

                <CustomFormSwitchToggle
                  tooltipTitle={tooltip.allowInfants}
                  label="Allow infants"
                  checked={props.pricingData?.hotel_pricing?.allow_infants}
                  onChange={() =>
                    props.updateStepAction(pricingStep, {
                      ...props.pricingData,
                      hotel_pricing: {
                        ...props.pricingData?.hotel_pricing,
                        allow_infants: !props.pricingData?.hotel_pricing?.allow_infants
                      }
                    })
                  }
                />
                {props.pricingData?.hotel_pricing?.allow_infants && (
                  <>
                    <CustomFormInput
                      type="number"
                      min={0}
                      step={1}
                      name="infant_price"
                      id="infant_price"
                      label="Price per infant"
                      tooltipTitle={tooltip.pricePerInfant}
                      value={props.pricingData?.hotel_pricing?.infant_price}
                      onInputBlur={value =>
                        props.updateStepAction(pricingStep, {
                          ...props.pricingData,
                          hotel_pricing: {
                            ...props.pricingData?.hotel_pricing,
                            infant_price: value
                          }
                        })
                      }
                      errorField={props.errorField}
                      isRequired={true}
                      alignRow={true}
                      maskedNumberInput={true}
                    />
                    <CustomFormInput
                      maxLength="15"
                      type="text"
                      name="infant_price_description"
                      id="infant_price_description"
                      label="Infant price description"
                      tooltipTitle={tooltip.infantPriceDescription}
                      value={props.pricingData?.hotel_pricing?.infant_price_description}
                      onInputChange={event =>
                        props.updateStepAction(pricingStep, {
                          ...props.pricingData,
                          hotel_pricing: {
                            ...props.pricingData?.hotel_pricing,
                            infant_price_description: event.target.value
                          }
                        })
                      }
                      errorField={null}
                      alignRow={true}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  pricingData: state.offers.pricingData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateStepAction: offersActionCreators.updateStepAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingStep);
