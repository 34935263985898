// Helpers
import ApiHelper from "../../utils/apiHelper";
import { providerRootUrl, pagesUrl, removeTrailingSlash } from "../../utils/panel-helpers/helpers";
// Actions
import { globalActionCreators } from "./globalModule";

export const dashboardActionTypes = {
  SET_DASHBOARD_MAIN_DATA: "SET_DASHBOARD_MAIN_DATA",
  SET_DASHBOARD_AVERAGES_DATA: "SET_DASHBOARD_AVERAGES_DATA",
  SET_DASHBOARD_GRAPH_DATA: "SET_DASHBOARD_GRAPH_DATA",
  SET_TABLE_DATA_IN_DASHBOARD: "SET_TABLE_DATA_IN_DASHBOARD"
};

export const initialState = {
  tableDataInDashboard: {
    salesByDistributor: { sortedData: [], column: null, direction: "desc" },
    salesByOffer: { sortedData: [], column: null, direction: "desc" }
  },
  dashboardData: {
    totalBookings: 0,
    totalRevenue: 0,
    totalCommission: 0
  },
  mainGraphData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.SET_TABLE_DATA_IN_DASHBOARD:
      return {
        ...state,
        tableDataInDashboard: action.tableDataInDashboard
      };
    case dashboardActionTypes.SET_DASHBOARD_MAIN_DATA:
      return {
        ...state,
        dashboardData: action.dashboardData
      };
    case dashboardActionTypes.SET_DASHBOARD_AVERAGES_DATA:
      return {
        ...state,
        dashboardData: action.dashboardAveragesData
      };
    case dashboardActionTypes.SET_DASHBOARD_GRAPH_DATA:
      return {
        ...state,
        mainGraphData: action.mainGraphData
      };

    default:
      return state;
  }
};

export const dashboardActionCreators = {
  loadDashboardDataAction: (selectedDateRange, currentPage) => async (dispatch, getState) => {
    // trigger Global Loader while loading Dashboard data only if Current Page is Dashboard Page (rootUrl "/")
    let rootUrlWithoutSlash = removeTrailingSlash(`/${providerRootUrl}`);
    if (currentPage === rootUrlWithoutSlash) {
      dispatch(globalActionCreators.toggleGlobalLoaderAction(true));
    }

    try {
      const res = await ApiHelper.get(
        `/v1/dashboard/` +
          selectedDateRange.startDate.format("DD-MM-YYYY") +
          `/` +
          selectedDateRange.endDate.format("DD-MM-YYYY")
      );

      const dashboardData = res.data;

      dispatch({
        type: dashboardActionTypes.SET_DASHBOARD_AVERAGES_DATA,
        dashboardAveragesData: {
          totalBookings: dashboardData.booking_revenue_average.number_of_bookings,
          totalRevenue: Number(dashboardData.booking_revenue_average.sum_of_revenue),
          averagesUnitsPerBookings: Number(dashboardData.booking_revenue_average.averages_units_per_bookings),
          averagesRevenuePerBookings: Number(dashboardData.booking_revenue_average.total_price)
        }
      });
      dispatch({
        type: dashboardActionTypes.SET_DASHBOARD_GRAPH_DATA,
        mainGraphData: dashboardData.main_graph
      });
      dispatch({
        type: dashboardActionTypes.SET_TABLE_DATA_IN_DASHBOARD,
        tableDataInDashboard: {
          salesByDistributor: {
            sortedData: dashboardData.sales_by_distributor,
            column: null,
            direction: "desc"
          },
          salesByOffer: { sortedData: dashboardData.sales_by_offer, column: null, direction: "desc" }
        }
      });

      // turn off Global Loader after loading Dashboard data only if Current Page is Dashboard Page (rootUrl "/")
      if (currentPage === rootUrlWithoutSlash) {
        dispatch(globalActionCreators.toggleGlobalLoaderAction(false));
      }
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    }
  },
  setTableDataInDashboardAction: tableDataInDashboard => {
    return {
      type: dashboardActionTypes.SET_TABLE_DATA_IN_DASHBOARD,
      tableDataInDashboard
    };
  },
  setMainDashboardDataAction: dashboardData => {
    return {
      type: dashboardActionTypes.SET_DASHBOARD_MAIN_DATA,
      dashboardData
    };
  }
};
