const LoginInput = ({
  id,
  name,
  type,
  placeholder,
  onChange,
  isRequired,
  mainClass = "login__input form-control",
  customClasses,
  onKeyPress
}) => {
  const customInputClass = customClasses ? customClasses : "";
  return (
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      required={isRequired}
      className={
        isRequired ? `${mainClass} ${mainClass}--disabled ${customInputClass}` : `${mainClass} ${customInputClass}`
      }
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
};

export default LoginInput;
