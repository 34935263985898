// icons
// @ts-ignore
import { checkGreyIcon, checkWhiteIcon } from "@gl-shared/shared/vector";

const CustomButtonStep = ({
  onClick,
  buttonText,
  isDisabled,
  isSelected,
  mainClass,
  customClasses,
  showStepNumber,
  index,
  showCheckmarkIcon
}: any) => {
  const customBtnClass = customClasses ? customClasses : "";

  return (
    <button
      id={index}
      disabled={isDisabled}
      className={
        isSelected
          ? isDisabled
            ? `${mainClass} ${mainClass}--selected  ${mainClass}--disabled ${customBtnClass}`
            : `${mainClass} ${mainClass}--selected  ${customBtnClass}`
          : isDisabled
          ? `${mainClass} ${mainClass}--disabled ${customBtnClass}`
          : `${mainClass} ${customBtnClass}`
      }
      onClick={onClick}
    >
      {showStepNumber !== false && <span className="step-number">{index}</span>}
      {buttonText}
      {showCheckmarkIcon && (
        <div className={`${mainClass}__checkmark`}>
          <img src={isSelected ? checkWhiteIcon : checkGreyIcon} alt="checkmark" />
        </div>
      )}
    </button>
  );
};

CustomButtonStep.defaultProps = {
  type: "submit",
  isDisabled: false,
  mainClass: "btn-green",
  showStepNumber: true,
  showCheckmarkIcon: false
};

export default CustomButtonStep;
