// @ts-ignore
import { errorIcon, formSuccessfulIcon } from "@gl-shared/shared/vector";

const FormMessage = (props: any) => {
  return (
    <div>
      {props.formSuccessful === true ? (
        <div className="form-message">
          <img className="form-message__icon" src={formSuccessfulIcon} alt="formsuccess-icon" />
          {props.form === "AddEmployee" ? (
            <div className="form-message__text">The employee was added successfully!</div>
          ) : null}
          {props.form === "EditEmployee" ? (
            <div className="form-message__text">The employee was updated successfully!</div>
          ) : null}
          {props.form === "DeleteEmployee" ? (
            <div className="form-message__text">The employee was deleted successfully!</div>
          ) : null}
          {props.form === "AddLogin" ? (
            <div className="form-message__text">The login was created successfully!</div>
          ) : null}
          {props.form === "EditLogin" ? (
            <div className="form-message__text">The login was updated successfully!</div>
          ) : null}
          {props.form === "DeleteLogin" ? (
            <div className="form-message__text">The login was deleted successfully!</div>
          ) : null}
          {props.form === "AddCostCenter" ? (
            <div className="form-message__text">The cost center was created successfully!</div>
          ) : null}
          {props.form === "EditCostCenter" ? (
            <div className="form-message__text">The cost center was updated successfully!</div>
          ) : null}
          {props.form === "DeleteCostCenter" ? (
            <div className="form-message__text">The cost center was deleted successfully!</div>
          ) : null}
          {props.form === "AddReference" ? (
            <div className="form-message__text">The reference was created successfully!</div>
          ) : null}
          {props.form === "EditReference" ? (
            <div className="form-message__text">The reference was updated successfully!</div>
          ) : null}
          {props.form === "DeleteReference" ? (
            <div className="form-message__text">The reference was deleted successfully!</div>
          ) : null}
          {props.form === "Settings" ? (
            <div className="form-message__text">Your settings were updated successfully!</div>
          ) : null}
          {props.form === "TermsAndConditions" ? (
            <div className="form-message__text">
              Your hotel was successfully onboarded as a service provider. You can now start adding offers.
            </div>
          ) : null}
          {props.form === "AddOffer" ? (
            <div className="form-message__text">The offer was created successfully!</div>
          ) : null}
          {props.form === "EditOffer" ? (
            <div className="form-message__text">The offer was updated successfully!</div>
          ) : null}
          {props.form === "DeleteOffer" ? (
            <div className="form-message__text">The offer was deleted successfully!</div>
          ) : null}
        </div>
      ) : null}
      {props.formError === true ? (
        <div className="nodata">
          <img className="error__icon" src={errorIcon} alt="error-icon" />
          <div className="nodata__text">There was an issue handling your request.</div>
        </div>
      ) : null}
      {props.form === "HotelSelectionNotice" ? (
        <div className="form-message__grey">
          <img className="form-message__grey-icon" src={errorIcon} alt="error-icon" />
          <div className="form-message__grey-text">
            You are now viewing data for “{props.hotelName}”. In this view, you can only select one hotel at a time.
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FormMessage;
