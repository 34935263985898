import moment from "moment";
// helpers
import ApiHelper from "../../utils/apiHelper";
// actions
import { globalActionCreators } from "./globalModule";

export const homeActionTypes = {
  SET_DATE_RANGE_SELECTION: "SET_DATE_RANGE_SELECTION",
  SET_HOTEL_DATA: "SET_HOTEL_DATA",
  UPDATE_HOTEL_DATA: "UPDATE_HOTEL_DATA"
};

export const initialState = {
  dateRangeSelection: {
    startDate: moment().subtract(6, "days"),
    endDate: moment()
  },
  noData: false,
  hotelData: {
    hotelName: "",
    hotelUser: "",
    hotelUserId: "",
    hotelCurrency: "",
    hotelGroup: [],
    hotelEventCategories: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homeActionTypes.SET_DATE_RANGE_SELECTION:
      return {
        ...state,
        dateRangeSelection: action.dateRangeSelection
      };
    case homeActionTypes.SET_HOTEL_DATA:
      return {
        ...state,
        hotelData: action.hotelData,
        account_settings: action?.hotelData?.account_settings
      };
    case homeActionTypes.UPDATE_HOTEL_DATA:
      return {
        ...state,
        hotelData: action.hotelData
      };
    default:
      return state;
  }
};

export const homeActionCreators = {
  setDateRangeSelectionAction: dateRangeSelection => {
    return {
      type: homeActionTypes.SET_DATE_RANGE_SELECTION,
      dateRangeSelection
    };
  },
  // This will be called after user call terms & conditions EP for Hotel Offers:
  updateHotelDataAction: hotelData => {
    return {
      type: homeActionTypes.UPDATE_HOTEL_DATA,
      hotelData
    };
  },
  loadHotelInfoAction: () => async (dispatch, getState) => {
    try {
      const res = await ApiHelper.get(`/v1/hotel-info`);

      const infoResponse = res.data;
      dispatch({
        type: homeActionTypes.SET_HOTEL_DATA,
        hotelData: {
          hotelUser: infoResponse?.full_name,
          hotelUserId: infoResponse?.id,
          hotelId: infoResponse?.provider?.id,
          hotelName: infoResponse?.provider?.name,
          hotelCurrency: infoResponse?.provider?.destination?.currency,
          hotelVatTaxRate: infoResponse?.provider?.destination?.tax_rate,
          defaultHotelSaasModel: infoResponse?.provider?.saas_model,
          hotel_offer_terms_conditions: infoResponse?.provider?.hotel_offer_terms_conditions,
          hotelGroup: [],
          hotelEventCategories: infoResponse?.provider?.destination?.hotel_offer_categories
        }
      });
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    }
  }
};
