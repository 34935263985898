// icons
import { loadingDotsDarkGreyIcon } from "@gl-shared/shared/vector";

const OffersStepLoading = ({ message }) => {
  return (
    <div className="hotel-offers__step__loader">
      <img src={loadingDotsDarkGreyIcon} alt="loading" />
      <div className="hotel-offers__step__loader-message">{message}</div>
    </div>
  );
};

export default OffersStepLoading;
