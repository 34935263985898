import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { offersActionCreators } from "../../../common/store/modules/offersModule";
// helpers
import { getCheckedCategoryIds, offersStep, tooltip, updateCategoryIds } from "../offersHelpers";
// components
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomFormTextArea } from "@gl-shared/shared/ui";
import { CustomTooltip } from "@gl-shared/shared/ui";
import { CustomCheckbox } from "@gl-shared/shared/ui";

const offerDetailsStep = offersStep.offerDetails;

const OfferDetailsStep = props => {
  const checkedCategoryIds = getCheckedCategoryIds(props.hotelEventCategories, props.offerDetailsData.event_categories);

  const durationOnKeyDown = event => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };

  const durationOnInput = event => (event.target.value = event.target.value.replace(/[^0-9]*/g, ""));

  return (
    <div className="row">
      <div className="col-lg-6 left-side">
        <CustomFormInput
          name="offer_name"
          id="offer_name"
          label="Offer Name"
          tooltipTitle={tooltip.offerName}
          value={props.offerDetailsData?.name}
          onInputChange={event =>
            props.updateStepAction(offerDetailsStep, { ...props.offerDetailsData, name: event.target.value })
          }
          errorField={props.errorField}
          isRequired={true}
          alignRow={true}
        />
        <CustomFormInput
          type="number"
          min={0}
          step={1}
          onKeyDown={durationOnKeyDown} // disable decimal numbers
          onInput={durationOnInput} // disable decimal numbers
          name="duration"
          id="duration"
          label="Duration in minutes"
          tooltipTitle={tooltip.duration}
          value={props.offerDetailsData?.duration_in_minutes}
          onInputChange={event =>
            props.updateStepAction(offerDetailsStep, {
              ...props.offerDetailsData,
              duration_in_minutes: event.target.value
            })
          }
          errorField={props.errorField}
          alignRow={true}
        />
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            Category<span className="mandatory-star">*</span>
            <span className="label-tooltip">
              <CustomTooltip title={tooltip.categories} placement="right-start" />
            </span>
          </label>

          <div className="col-sm-8">
            {props.hotelEventCategories?.map(category => {
              return (
                <div key={category.id} className="form-group__details-checkbox">
                  <CustomCheckbox
                    label={category.display_name}
                    labelClass="form-group__details-checkbox__label"
                    isChecked={!!checkedCategoryIds.find(catId => category.id === catId)}
                    value={!!checkedCategoryIds.find(catId => category.id === catId)}
                    iconSize={20}
                    onClick={() =>
                      props.updateStepAction(offerDetailsStep, {
                        ...props.offerDetailsData,
                        event_categories: updateCategoryIds(category.id, checkedCategoryIds)
                      })
                    }
                    lightColorCheckbox={true}
                  />
                </div>
              );
            })}
            <div className="form-group__error-message">
              {props.errorField?.event_categories ? "You need to select at least one category" : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 right-side">
        <CustomFormTextArea
          name="short_description"
          id="short_description"
          label="Short Description"
          tooltipTitle={tooltip.shortDescription}
          value={props.offerDetailsData.short_description}
          isRequired={true}
          onChange={event =>
            props.updateStepAction(offerDetailsStep, {
              ...props.offerDetailsData,
              short_description: event.target.value
            })
          }
          errorField={props.errorField}
        />
        <CustomFormTextArea
          name="long_description"
          id="long_description"
          label="Long Description"
          tooltipTitle={tooltip.longDescription}
          value={props.offerDetailsData.long_description}
          isRequired={true}
          onChange={event =>
            props.updateStepAction(offerDetailsStep, {
              ...props.offerDetailsData,
              long_description: event.target.value
            })
          }
          errorField={props.errorField}
          textareaRows={7}
          bigTextarea={true}
        />
        <CustomFormTextArea
          name="post-booking"
          label="Post Booking Info"
          tooltipTitle={tooltip.postBookingInfo}
          value={props.offerDetailsData.post_booking_info}
          onChange={event =>
            props.updateStepAction(offerDetailsStep, {
              ...props.offerDetailsData,
              post_booking_info: event.target.value
            })
          }
          errorField={null}
        />
        <CustomFormTextArea
          name="cancellation-policy"
          label="Cancellation policy"
          tooltipTitle={tooltip.cancellation}
          value={props.offerDetailsData.cancellation_policy}
          onChange={event =>
            props.updateStepAction(offerDetailsStep, {
              ...props.offerDetailsData,
              cancellation_policy: event.target.value
            })
          }
          errorField={null}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  offerDetailsData: state.offers.offerDetailsData
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateStepAction: offersActionCreators.updateStepAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsStep);
