import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useOutletContext } from "react-router-dom";
// helpers
import { pagesUrl, providerRootUrl } from "../../common/utils/panel-helpers/helpers";
import { checkForEmptyField, checkForValidEmail } from "@gl-shared/shared/helpers";
// actions
import { loginsActionCreators } from "../../common/store/modules/loginsModule";
// components
import { CustomFormInput } from "@gl-shared/shared/ui";
import { CustomInput } from "@gl-shared/shared/ui";
import { CustomAlertDialog } from "@gl-shared/shared/ui";
import { CustomButton } from "@gl-shared/shared/ui";

const EditLogin = props => {
  const [loginName, setLoginName] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPhone, setLoginPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorField, setErrorField] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    password: false
  });
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const navigate = useNavigate();
  const outletContext = useOutletContext();

  useEffect(() => {
    setLoginName(props.selectedLogin.full_name);
    setLoginEmail(props.selectedLogin.email);
    setLoginPhone(props.selectedLogin.phone_number);
  }, []);

  const handleSubmit = async () => {
    let errorCheck = true;
    let errorField = {
      name: false,
      email: false,
      phoneNumber: false,
      password: false
    };

    if (loginName === "") {
      errorCheck = false;
      errorField.name = true;
    }
    if (checkForEmptyField(loginEmail) || checkForValidEmail(loginEmail)) {
      errorCheck = false;
      errorField.email = true;
    }
    if (loginPhone === "") {
      errorCheck = false;
      errorField.phoneNumber = true;
    }
    if (loginPassword !== "" && loginPassword.length < 8) {
      errorCheck = false;
      errorField.password = true;
    }

    setErrorField(errorField);

    if (errorCheck === false) {
      return false;
    }

    props.updateLoginsAction(
      loginName,
      loginEmail,
      loginPhone,
      false,
      loginPassword,
      outletContext.handleFormMessage,
      outletContext.loadLogins,
      navigate
    );
  };

  const handleDeleteLogin = async () => {
    setIsAlertDialogOpen(true);
  };
  const handleDeleteLoginConfirmation = async () => {
    setIsAlertDialogOpen(false);
    props.deleteLoginAction(outletContext.handleFormMessage, outletContext.loadLogins, navigate);
  };

  const handleCloseAlertDialog = () => {
    setIsAlertDialogOpen(false);
  };

  return (
    <div className="employees component">
      <CustomAlertDialog
        isOpen={isAlertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleBtnLeft={handleCloseAlertDialog}
        handleBtnRight={handleDeleteLoginConfirmation}
        title={"ARE YOU SURE?"}
        content={"Are you sure you want to permanently delete this user?"}
        btnLeftLabel={"CANCEL"}
        btnRightLabel={"DELETE"}
      />
      <div className="row">
        <div className="col-lg-6">
          <div className="ibox form__ibox">
            <div className="ibox-title">
              <h5>Edit Login</h5>
            </div>
            <div className="ibox-content">
              <div className="hotel__form">
                <CustomFormInput
                  showTooltip={false}
                  name="loginName"
                  id="name"
                  label="Name"
                  onInputChange={event => setLoginName(event.target.value)}
                  errorField={errorField}
                  isRequired={true}
                  value={loginName}
                />
                <CustomFormInput
                  showTooltip={false}
                  name="loginEmail"
                  id="email"
                  label="Email"
                  onInputChange={event => setLoginEmail(event.target.value)}
                  errorField={errorField}
                  isRequired={true}
                  value={loginEmail}
                />
                <CustomFormInput
                  maskedNumberInput={true}
                  maskedPhoneNumber={true}
                  showTooltip={false}
                  name="loginPhone"
                  id="phoneNumber"
                  label="Phone Number"
                  errorField={errorField}
                  onInputBlur={value => setLoginPhone(value)}
                  isRequired={true}
                  value={loginPhone}
                />
                <div className="form-group  row">
                  <label className="col-sm-4 col-form-label field--required">Password</label>
                  <div className="col-sm-8 pw-field__container">
                    <CustomInput
                      name="loginPassword"
                      id="password"
                      type="password"
                      errorField={errorField}
                      onInputChange={event => setLoginPassword(event.target.value)}
                      value={loginPassword}
                      showStar={false}
                      isRequired={true}
                    />
                    <div className="pw-field__message">min. 8 characters | leave blank to keep current password</div>
                  </div>
                </div>
                <div className="form-group  row">
                  <div className="left-side">
                    <CustomButton
                      isDisabled={false}
                      mainClass="btn-red"
                      customClasses="btn-red--height-l"
                      onClick={handleDeleteLogin}
                      buttonText="DELETE"
                    />
                  </div>
                  <div className="right-side">
                    <CustomButton
                      isDisabled={false}
                      mainClass="btn-gray"
                      customClasses="btn-gray--height-l"
                      onClick={() => {
                        navigate(`/${providerRootUrl}/${pagesUrl.logins}`);
                      }}
                      buttonText="CANCEL"
                    />
                    <CustomButton
                      isDisabled={false}
                      mainClass="btn-green"
                      customClasses="btn-green--height-l"
                      onClick={handleSubmit}
                      buttonText="SAVE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedLogin: state.logins.selectedLogin
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateLoginsAction: loginsActionCreators.updateLoginsAction,
      deleteLoginAction: loginsActionCreators.deleteLoginAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLogin);
