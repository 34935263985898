import { getLoadingSectionArray } from "../../utils/panel-helpers/helpers";

export const globalActionTypes = {
  SET_REQUEST_ERROR: "SET_REQUEST_ERROR",
  TOGGLE_GLOBAL_LOADER: "TOGGLE_GLOBAL_LOADER",
  SET_FORM_DATA: "SET_FORM_DATA",
  TOGGLE_SECTION_LOADER: "TOGGLE_SECTION_LOADER"
};

export const initialState = {
  requestError: false,
  loading: false,
  sectionLoadingNames: [],
  formData: {
    formSuccessful: false,
    formError: false,
    form: "",
    hotelName: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case globalActionTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.requestError
      };
    case globalActionTypes.TOGGLE_GLOBAL_LOADER:
      return {
        ...state,
        loading: action.loading
      };
    case globalActionTypes.TOGGLE_SECTION_LOADER:
      const updated = getLoadingSectionArray(
        state.sectionLoadingNames,
        action.sectionLoading,
        action.sectionLoadingNames
      );

      return {
        ...state,
        sectionLoadingNames: updated
      };
    case globalActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...action.formData
        }
      };

    default:
      return state;
  }
};

export const globalActionCreators = {
  handleErrorAction: () => dispatch => {
    dispatch({
      type: globalActionTypes.TOGGLE_GLOBAL_LOADER,
      loading: false
    });
    dispatch({
      type: globalActionTypes.SET_REQUEST_ERROR,
      requestError: true
    });
    setTimeout(
      () =>
        dispatch({
          type: globalActionTypes.SET_REQUEST_ERROR,
          requestError: false
        }),
      3000
    );
  },
  toggleGlobalLoaderAction: loading => dispatch => {
    dispatch({
      type: globalActionTypes.TOGGLE_GLOBAL_LOADER,
      loading: loading
    });
  },
  toggleSectionLoaderAction: (sectionLoading, sectionLoadingNames) => {
    return {
      type: globalActionTypes.TOGGLE_SECTION_LOADER,
      sectionLoading,
      sectionLoadingNames
    };
  },
  setFormDataAction: formData => {
    return {
      type: globalActionTypes.SET_FORM_DATA,
      formData
    };
  }
};
