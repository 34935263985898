// helpers
import { appSection } from "../../utils/panel-helpers/helpers";
import ApiHelper from "../../utils/apiHelper";
// actions
import { globalActionCreators } from "./globalModule";

export const settingsActionTypes = {
  SET_SETTINGS_DATA: "SET_SETTINGS_DATA"
};

export const initialState = {
  hotelSettingsData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case settingsActionTypes.SET_SETTINGS_DATA:
      return {
        ...state,
        hotelSettingsData: action.hotelSettingsData
      };
    default:
      return state;
  }
};

export const settingsActionCreators = {
  loadHotelSettingsAction: (hotelId, callSectionLoader) => async (dispatch, getState) => {
    const sectionLoadingNamesState = getState().global.sectionLoadingNames;
    if (sectionLoadingNamesState?.includes(appSection?.hotelSettings)) {
      // disable new API call if we are still waiting for response
      return;
    }
    const sectionUrl = appSection?.providerSettings;

    callSectionLoader(true, sectionUrl);

    try {
      const res = await ApiHelper.get(`/v1/settings`);

      dispatch({
        type: settingsActionTypes.SET_SETTINGS_DATA,
        hotelSettingsData: res.data
      });
    } catch (error) {
      dispatch(globalActionCreators.handleErrorAction());
    } finally {
      callSectionLoader(false, sectionUrl);
    }
  },
  updateSettingsAction:
    (
      name,
      website,
      email,
      street,
      streetNumber,
      zipCode,
      city,
      phone,
      contactPerson,
      costCenterMandatory,
      referenceMandatory,
      onHandleFormMessage,
      onLoadSettings
    ) =>
    async (dispatch, getState) => {
      try {
        let bodyFormData = {
          name: name,
          email: email,
          address: {
            street: street,
            houseNumber: streetNumber,
            zipCode: zipCode,
            city: city,
            phoneNumber: phone
          },
          accountSettings: {}
        };

        const res = await ApiHelper.post(`/v1/settings/edit`, {}, JSON.stringify(bodyFormData));

        onLoadSettings();
        onHandleFormMessage(res.data.status, "Settings");
      } catch (error) {
        console.log(error);
      }
    }
};
