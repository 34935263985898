import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
//components
import LoginButton from "./LoginButton";
import LoginInput from "./LoginInput";
// helpers
import { pagesUrl, providerRootUrl } from "../../../common/utils/panel-helpers/helpers";
// actions
import { useActions } from "../../../common/hooks/useActions";
import { loginActionCreators } from "../loginModule";

const LoginForm = () => {
  const [isCheckedKeepMeLoggedIn, setIsCheckedKeepMeLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // @ts-ignore
  const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));

  // local state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // actions
  const getUserLogin = useActions(loginActionCreators?.getUserLoginAction, []);

  // states
  const errorMessage = useSelector((state: any) => state.login.errorMessage);

  useEffect(() => {
    setIsCheckedKeepMeLoggedIn(loggedIn);
  }, [loggedIn]);

  const handleLogin = async () => {
    getUserLogin(username, password, isCheckedKeepMeLoggedIn, navigate);
  };

  const onKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      getUserLogin(username, password, isCheckedKeepMeLoggedIn, navigate);
    }
  };

  return (
    <div>
      {location?.state?.passwordResetSuccessfully && (
        <div className="login__pass-update-msg">
          Your password was updated. <br /> Please log in below.
        </div>
      )}
      {errorMessage && <div className="login__error">{errorMessage}</div>}
      <div className="form-group">
        <LoginInput
          type="text"
          id="login_form__username"
          name="login_form[_username]"
          placeholder="E-Mail"
          onChange={(e: any) => setUsername(e.target.value)}
          onKeyPress={onKeyPress}
          customClasses={undefined}
          isRequired={undefined}
        />
      </div>
      <div className="form-group">
        <LoginInput
          type="password"
          id="login_form__password"
          name="login_form[_password]"
          placeholder="Password"
          onChange={(e: any) => setPassword(e.target.value)}
          onKeyPress={onKeyPress}
          customClasses={undefined}
          isRequired={undefined}
        />
      </div>
      <div className="login__forgot-password-wrapper">
        <div
          onClick={() => navigate(`/${providerRootUrl}/${pagesUrl.resetPassword}`)}
          className="login__forgot-password"
        >
          FORGOT PASSWORD?
        </div>
      </div>
      <LoginButton buttonText="Login" onClick={handleLogin} customClasses={undefined} />
      <div className="gl-checkbox-wrapper">
        <div className="gl-checkbox">
          <input
            type="checkbox"
            id="remember_me"
            name="_remember_me"
            checked={loggedIn}
            onChange={e => {
              localStorage.setItem("loggedIn", JSON.stringify(e.target.checked));
              setIsCheckedKeepMeLoggedIn(e.target.checked);
            }}
          />
          <label htmlFor="remember_me">KEEP ME LOGGED IN</label>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
